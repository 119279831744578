import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useCreateUserMutation } from "../../common/services/users";
import { StyledForm } from "./UserList.styles";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { validationCharacters } from "../../constants";
import { ArrowLeftOutlined } from "@ant-design/icons";
import axios from "axios";

const { Option } = Select;

const CreateUser = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [createUser] = useCreateUserMutation();

  const [schools, setSchools] = useState([]);

  const user = useSelector((state) => state.userDetails);
  const roleList = useSelector((state) => state.roleList);

  useEffect(() => {
    if (user?.school) {
      form.setFieldsValue({
        school: user.school._id,
      });
    }
  }, [user, form]);

  useEffect(() => {
    getAllSuperAdminSchools();
  }, []);

  const getAllSuperAdminSchools = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/userservice/api/user/getAllSuperAdminSchools`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      setSchools(response?.data?.data);
      console.log(response, "response check get all super admin schools");
    } catch (error) {
      console.log(error, "error check get all super admin schools");
    }
  };

  const onFinish = async (values) => {
    const {
      fname,
      lname,
      phone,
      email,
      school,
      role,
      password,
      confirmPassword,
    } = values;
    const formData = new FormData();
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("school", school);
    formData.append("role", role);
    formData.append("password", password);
    formData.append("confirmPassword", confirmPassword);

    try {
      const response = await createUser(formData);
      console.log(response, "response check create user");
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error("User Already Exists");
      }
      navigate(`/user`);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const validatePhone = (rule, value, callback) => {
    const phoneRegex = /^\d{10}$/;
    if (!value || !value.match(phoneRegex)) {
      callback("Please enter a valid phone number");
    } else {
      callback();
    }
  };

  return (
    <>
      {/* <Button size="small" onClick={() => navigate("/user")}> */}
      <ArrowLeftOutlined
        className="cursor-pointer"
        onClick={() => navigate("/user")}
      />
      {/* </Button> */}
      <h2>Create User</h2>
      <StyledForm form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="fname"
              label="First Name"
              required
              rules={[
                { required: false, message: "Please enter your first name" },
                {
                  validator: (rule, value, callback) =>
                    validationCharacters(
                      rule,
                      value,
                      callback,
                      "Please enter a valid first name"
                    ),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="lname"
              label="Last Name"
              required
              rules={[
                { required: false, message: "Please enter your last name" },
                {
                  validator: (rule, value, callback) =>
                    validationCharacters(
                      rule,
                      value,
                      callback,
                      "Please enter a valid last name"
                    ),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="phone"
              label="Phone"
              required
              rules={[
                { required: false, message: "Please enter your phone number" },
                { validator: validatePhone },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="school"
              label="School"
              rules={[{ required: true, message: "Please select your school" }]}
            >
              {user?.role?.name === "Super Admin" ? (
                <Select placeholder="Select a School">
                  {schools?.map((item) => (
                    <Option value={item?._id} key={item?._id}>
                      {item?.schoolName}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Select placeholder="Select a School" disabled>
                  <Option value={user.school._id} key={user.school._id}>
                    {user.school.schoolName}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: "Please select your role" }]}
            >
              {/* {roleList && user?.role?.name === "Super Admin" && (
                <Select placeholder="Select a role">
                  {roleList?.map((item) => (
                    <Option value={item._id} key={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              )} */}
              {roleList && (
                <Select placeholder="Select a role">
                  {roleList
                    ?.filter((item) => item.name !== "Super Admin")
                    .map((item) => (
                      <Option value={item._id} key={item._id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: "Please enter a password" }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
              <Button
                onClick={() => navigate("/user")}
                style={{ marginLeft: 8 }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledForm>
    </>
  );
};

export default CreateUser;
