import styled from "styled-components";
import { Form } from "antd";

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  margin-bottom: 10px
`;

export const StyledForm = styled(Form)`
  margin: 0 auto;
  padding: 50px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

export const OptionWrapper = styled.div`
    padding: 20px;
    border: 1px solid #c7c2c2;
    margin-bottom: 15px;
    border-radius: 5px;
`