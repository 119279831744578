import { createSlice } from "@reduxjs/toolkit";

const masterSlice = createSlice({
    name: "masterSeletedValue",
    initialState: [],
    reducers: {
        masterSeletedValue: (state, action) => {
            const existingIndex = state.findIndex(item => item.type === action.payload.type);
            if (existingIndex !== -1) {
                state[existingIndex] = action.payload;
            } else {
                state.push(action.payload);
            }
        },
    },
});

export const { masterSeletedValue } = masterSlice.actions;
export default masterSlice.reducer;
