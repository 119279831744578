import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import {
  useDeleteUserMutation,
  useGetAllUsersMutation,
} from "../../common/services/users";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { PageHeader } from "./UserList.styles";
import UserModal from "../../components/Modal";
import { Button, Input } from "antd";
import { toast } from "react-toastify";
import { useGetAllRolesMutation } from "../../common/services/roles";
import { useDispatch, useSelector } from "react-redux";
import { roleList } from "../../common/features/userRoleSlice";
import PermissionChecker from "../../components/PermissionChecker";
import axios from "axios";

const UserList = () => {
  const user = useSelector((state) => state.userDetails);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tempDeleteUserData, setTempDeleteUserData] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [superAdminAllUsers, setSuperAdminAllUsers] = useState([]);

  const [getAllUsers, { data, isLoading, error }] = useGetAllUsersMutation();
  const [getAllActiveRoles, { data: rolesList }] = useGetAllRolesMutation();
  const [deleteUser] = useDeleteUserMutation();

  console.log(user, "user list details check");

  const getAllSuperAdminUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/userservice/api/user/getAllUsersSuperAdmin`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setSuperAdminAllUsers(response?.data?.data);

      console.log(response, "response check get all super admin users");
    } catch (error) {
      console.log(error, "error check get all super admin users");
    }
  };

  useEffect(() => {
    getAllUsers({ schoolId: user?.school?._id });
    getAllActiveRoles();
    getAllSuperAdminUsers();
  }, []);

  useEffect(() => {
    dispatch(roleList(rolesList?.roles));
  }, [rolesList]);

  if (error) {
    toast.error(error.data.message);
  }

  const columns = [
    {
      title: "FirstName",
      dataIndex: "fname",
      key: "fname",
    },
    {
      title: "LastName",
      dataIndex: "lname",
      key: "lname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      searchable: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role) => <>{role?.name}</>,
    },
    {
      title: "School",
      dataIndex: "school",
      key: "school",
      render: (school) => <>{school?.schoolName}</>,
    },
  ];

  const handleCreateUserClick = () => {
    navigate(`/user/create`);
  };

  const handleEdit = (user) => {
    navigate(`/user/edit/${user._id}`);
  };

  const handleDelete = (user) => {
    setDeleteModalVisible(true);
    setTempDeleteUserData(user);
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    setDeleteModalVisible(false);
    const userId = {
      id: tempDeleteUserData._id,
    };

    try {
      const response = await deleteUser(userId);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      getAllUsers();
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const actions = [
    {
      key: "edit",
      icon: <EditFilled />,
      onClick: handleEdit,
      label: "Edit",
      style: { backgroundColor: "#7c18ff" },
      permission: { resource: "user", action: "update" },
    },
    {
      key: "delete",
      icon: <DeleteFilled />,
      onClick: handleDelete,
      label: "Delete",
      style: { backgroundColor: "#ff1818" },
      permission: { resource: "user", action: "delete" },
    },
  ];

  const filteredUsers =
    data?.Users &&
    data?.Users?.filter(
      (user) =>
        user?.fname?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        user?.lname?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        user?.email?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        user?.phone?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );
  const filteredSuperAdminUsers =
    superAdminAllUsers &&
    superAdminAllUsers?.filter(
      (user) =>
        user?.fname?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        user?.lname?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        user?.email?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        user?.phone?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );

  return (
    <>
      <h2 style={{ paddingLeft: "5px" }}>Users List</h2>
      <PageHeader>
        <Input
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ maxWidth: "200px" }}
        />
        <PermissionChecker resource="user" action="create">
          <Button onClick={handleCreateUserClick}>Create User</Button>
        </PermissionChecker>
      </PageHeader>
      <DataTable
        dataSource={
          user?.role?.name === "Super Admin"
            ? filteredSuperAdminUsers &&
              [...filteredSuperAdminUsers].sort(
                (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
              )
            : filteredUsers &&
              [...filteredUsers].sort(
                (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
              )
        }
        columns={columns}
        actions={actions}
        loading={isLoading}
      />
      <UserModal
        visible={deleteModalVisible}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={"Delete User"}
        description={"Are you sure you want to delete this user?"}
      />
    </>
  );
};

export default UserList;
