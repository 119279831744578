import { createSlice } from "@reduxjs/toolkit";

const chapterSlice = createSlice({
    name: "chaptersList",
    initialState: [],
    reducers: {
        chaptersList: (state, action) => {
            return action.payload
        },
    },
});

export const { chaptersList } = chapterSlice.actions;
export default chapterSlice.reducer;
