import React, { useEffect, useState } from "react";
import { Form, Input, Col, Row, Select, Button, Spin } from "antd";
import { useSelector } from "react-redux";
import { useCreateSectionMutation, useDeleteSectionMutation, useGetAllSectionMutation, useUpdateSectionMutation } from "../../common/services/section";
import DataTable from "../../components/DataTable";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { toast } from "react-toastify";
import UserModal from "../../components/Modal";
import PermissionChecker from "../../components/PermissionChecker";
import {  validationCharactersNumber } from "../../constants";

const Section = () => {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [filteredValue, setFilteredValue] = useState()
    const [editSection, setEditSection] = useState(null);
    const [updateTempSection, setUpdateTempSection] = useState()
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [tempDeleteData, setTempDeleteData] = useState();
    const [filterChapter, setFilterChapter] = useState()
    const [filteredChapterValue, setFilteredChapterValue] = useState()
    const [selectedSubject, setSelectedSubject] = useState()
    const [selectedChapter, setSelectedChapter] = useState()

    const user = useSelector((state) => state.userDetails)
    const chapterList = useSelector((state) => state.chaptersList)
    const masterSeletedValue = useSelector((state) => state.masterSeletedValue)
    const filteredSubject = useSelector((state) => state.filteredSubject)

    const [createSectionMute, { isLoading: createLoader }] = useCreateSectionMutation();
    const [updateSectionMute, { isLoading: updateLoader }] = useUpdateSectionMutation();
    const [deleteSectionMute] = useDeleteSectionMutation();
    const [getAllSection, { data: sectionList, isLoading }] = useGetAllSectionMutation();

    // console.log(sectionList, "sectionList");

    useEffect(() => {
        handleGetSectionList()
    }, [user]);

    const handleGetSectionList = () => {
        if (user.school) {
            const currentSchoolId = {
                schoolId: user.school._id,
            };
            getAllSection(currentSchoolId);
        }
    }

    useEffect(() => {
        if (filteredChapterValue) {
            const subjectRecords = filteredChapterValue.filter((item) => item?.subject?._id === selectedSubject);
            setFilterChapter(subjectRecords)
        }
    }, [selectedSubject])

    useEffect(() => {
        if (sectionList) {
            const filteredSections = sectionList?.sections.filter(section => {
                return section.subject?._id === selectedSubject && section.chapter?._id === selectedChapter;
            });
            setFilteredValue(filteredSections)
        }
    }, [selectedChapter, sectionList])

    useEffect(() => {
        const filteredSections = chapterList.filter(chapter => {
            return masterSeletedValue.every(filter => {
                return chapter[filter.type]?._id === filter.value;
            });
        });
        setFilteredChapterValue(filteredSections)
    }, [chapterList, masterSeletedValue])

    const handleSubjectChange = (id) => {
        setSelectedSubject(id)
        form.resetFields(['chapter']);
    }

    const handleChapterChange = (id) => {
        setSelectedChapter(id)
    }

    const handleEditSection = (section) => {
        setEditSection(true)
        setUpdateTempSection(section)
        form.setFieldsValue({
            sectionName: section.sectionName,
        });
    }

    const handleDeleteSection = (section) => {
        setDeleteModalVisible(true);
        setTempDeleteData(section);
    };

    const handleCancelDelete = () => {
        setDeleteModalVisible(false);
    };

    const handleConfirmDelete = async () => {
        setDeleteModalVisible(false);
        const formDataDelete = {
            "id": tempDeleteData._id
        }
        const response = await deleteSectionMute(formDataDelete);
        if (response.data) {
            toast.success(response.data.message)
        }
        if (response.error) {
            toast.error(response.error.data.message)
        }
        handleGetSectionList()
    };

    const handleCancelClick = () => {
        form.resetFields(['sectionName']);
        setEditSection(false)
    }

    const onFinish = async (values) => {
        const { sectionName, subject, chapter } = values;

        const formData = {
            sectionName,
            subjectId: subject,
            chapterId: chapter
        }

        masterSeletedValue.forEach(item => {
            switch (item.type) {
                case 'board':
                    formData.board = item.value;
                    break;
                case 'school':
                    formData.school = item.value;
                    break;
                case 'medium':
                    formData.medium = item.value;
                    break;
                case 'standard':
                    formData.standard = item.value;
                    break;
                default:
                    break;
            }
        });

        try {
            if (editSection) {
                const formDataUpdate = {
                    id: updateTempSection._id,
                    sectionName,
                }

                const response = await updateSectionMute(formDataUpdate);
                if (response.data) {
                    toast.success(response.data.message)
                }
                if (response.error) {
                    toast.error(response.error.data.message)
                }
                form.resetFields(['sectionName']);
                setEditSection(false)
                handleGetSectionList()
            } else {
                const response = await createSectionMute(formData);
                if (response.data) {
                    toast.success(response.data.message)
                }
                if (response.error) {
                    toast.error(response.error.data.message)
                }
                form.resetFields(['sectionName']);
                handleGetSectionList()
            }
        } catch (error) {
            console.error("Login error:", error);
        }
    };

    const columns = [
        {
            title: "Section Name",
            dataIndex: "sectionName",
            key: "sectionName",
        },
        {
            title: "Chapter",
            dataIndex: "chapter",
            key: "chapter",
            render: (chapter) => <>{chapter?.chapterName}</>,
        },
    ];

    const actions = [
        {
            key: "edit",
            icon: <EditFilled />,
            onClick: handleEditSection,
            label: "",
            style: { backgroundColor: "#14828f" },
            permission: { resource: "master", action: "update" }
        },
        {
            key: "delete",
            icon: <DeleteFilled />,
            onClick: handleDeleteSection,
            label: "",
            style: { backgroundColor: "#cb192f" },
            permission: { resource: "master", action: "delete" }
        },
    ];

    return (
        <>
            <h3>{editSection ? "Update" : "Create"} Section</h3>
            <Spin spinning={createLoader || updateLoader} fullscreen />
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={24}>
                    {!editSection &&
                        <>
                            <Col span={6}>
                                <Form.Item
                                    name="subject"
                                    label="Subject"
                                    rules={[{ required: true, message: "Please select your subject" }]}
                                >
                                    <Select placeholder="Select a Subject" onChange={handleSubjectChange}>
                                        {filteredSubject.map((item) => (
                                            <Option value={item._id} key={item._id}>{item.subjectName}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="chapter"
                                    label="Chapter"
                                    rules={[{ required: true, message: "Please select your chapter" }]}
                                >
                                    <Select placeholder="Select a Chapter" onChange={handleChapterChange}>
                                        {filterChapter?.map((item) => (
                                            <Option value={item._id} key={item._id}>{item.chapterName}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </>}
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name="sectionName"
                            label="Section Name"
                            required
                            rules={[
                                {
                                    required: false,
                                    message: "Please enter your section name",
                                },
                                { validator: validationCharactersNumber },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ display: "flex", alignItems: 'flex-end' }}>
                        <Form.Item>
                            <PermissionChecker resource="master" action="create">
                                <Button htmlType="submit">{editSection ? "Update" : "Add Section"}</Button>
                            </PermissionChecker>
                            {editSection && <Button onClick={handleCancelClick} style={{ marginLeft: "10px" }} danger >Cancel</Button>}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {filteredValue?.length > 0 &&
                <DataTable
                    dataSource={filteredValue && [...filteredValue].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))}
                    columns={columns}
                    actions={actions}
                    loading={isLoading}
                />
            }
            <UserModal
                visible={deleteModalVisible}
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                title={"Delete Section"}
                description={"Are you sure you want to delete this section?"}
            />
        </>
    );
};

export default Section;
