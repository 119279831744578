import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { usersApi } from "./services/users";
import { loginApi } from "./services/login";
import usersReducer from "./features/usersSlice";
import authReducer from "./features/authSlice";
import schoolReducer from "./features/schoolSlice";
import masterReducer from "./features/masterSlice";
import subjectReducer from "./features/subjectSlice";
import chapterReducer from "./features/chapterSlice";
import contentReducer from "./features/contentSlice";
import bannerReducer from "./features/bannerSlice"
import quizReducer from "./features/quizSlice"
import testReducer from "./features/testSlice"
import roleReducer from "./features/roleSlice"
import usersRoleReducer from "./features/userRoleSlice"
import permissionsReducer from "./features/permissionSlice"
import { rolesApi } from "./services/roles";
import { schoolsApi } from "./services/schools";
import { boardApi } from "./services/board";
import { mediumApi } from "./services/medium";
import { standardApi } from "./services/standard";
import { subjectApi } from "./services/subject";
import { chapterApi } from "./services/chapter";
import { conceptApi } from "./services/concept";
import { semesterApi } from "./services/semester";
import { sectionApi } from "./services/section";
import { contentApi } from "./services/content";
import { questionApi } from "./services/question";
import { quizApi } from "./services/quiz";
import { testApi } from "./services/test";
import { bannerApi } from "./services/banner";
import { studentApi } from "./services/student";

export const store = configureStore({
  reducer: {
    [loginApi.reducerPath]: loginApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [schoolsApi.reducerPath]: schoolsApi.reducer,
    [boardApi.reducerPath]: boardApi.reducer,
    [mediumApi.reducerPath]: mediumApi.reducer,
    [standardApi.reducerPath]: standardApi.reducer,
    [subjectApi.reducerPath]: subjectApi.reducer,
    [chapterApi.reducerPath]: chapterApi.reducer,
    [conceptApi.reducerPath]: conceptApi.reducer,
    [semesterApi.reducerPath]: semesterApi.reducer,
    [sectionApi.reducerPath]: sectionApi.reducer,
    [contentApi.reducerPath]: contentApi.reducer,
    [questionApi.reducerPath]: questionApi.reducer,
    [quizApi.reducerPath]: quizApi.reducer,
    [testApi.reducerPath]: testApi.reducer,
    [bannerApi.reducerPath]: bannerApi.reducer,
    [studentApi.reducerPath]: studentApi.reducer,
    auth: authReducer,
    userDetails: usersReducer,
    roleList: usersRoleReducer,
    getAllMaster: schoolReducer,
    masterSeletedValue: masterReducer,
    filteredSubject: subjectReducer,
    chaptersList: chapterReducer,
    masterContentSeletedValue: contentReducer,
    previewContentData: contentReducer,
    bannerData: bannerReducer,
    quizTempDetails: quizReducer,
    testTempDetails: testReducer,
    intialRoleList: roleReducer,
    permissionsList: permissionsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      loginApi.middleware,
      usersApi.middleware,
      rolesApi.middleware,
      schoolsApi.middleware,
      boardApi.middleware,
      mediumApi.middleware,
      standardApi.middleware,
      subjectApi.middleware,
      chapterApi.middleware,
      conceptApi.middleware,
      semesterApi.middleware,
      sectionApi.middleware,
      contentApi.middleware,
      questionApi.middleware,
      quizApi.middleware,
      testApi.middleware,
      bannerApi.middleware,
      studentApi.middleware
    ),
});

setupListeners(store.dispatch);
