import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Col, Row, Button, Upload, Spin } from "antd";
import { StyledForm } from "./Banner.styles";
import { UploadOutlined } from "@ant-design/icons";
import { useUploadBannerMutation } from "../../common/services/banner";
import { toast } from "react-toastify";
import { validationCharactersAlphabets } from "../../constants";

const UploadBanner = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [uploadBanner] = useUploadBannerMutation();
    const [bannerLoader, setBannerLoader] = useState(false)


    const onFinish = async (values) => {
        setBannerLoader(true)
        const { title, imageUrl } = values;
        const formData = new FormData();
        formData.append("title", title);
        if (imageUrl && imageUrl[0] && imageUrl[0].originFileObj) {
            formData.append("imageUrl", imageUrl[0].originFileObj);
        }

        try {
            const response = await uploadBanner(formData);
            if (response.data) {
                toast.success(response.data.message)
                setBannerLoader(false)
            }
            if (response.error) {
                toast.error(response.error.data.message)
                setBannerLoader(false)
            }
            navigate(`/banner`);
        } catch (error) {
            setBannerLoader(false)
            console.error("Login error:", error);
        }
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <>
            <h2>Upload Banner</h2>
            <Spin spinning={bannerLoader} fullscreen />
            <StyledForm form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="title"
                            label="Title"
                            required
                            rules={[
                                {
                                    required: false,
                                    message: "Please enter your banner title",
                                },
                                { validator: validationCharactersAlphabets },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="imageUrl"
                            label="Banner Image"
                            valuePropName="fileList"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your banner img",
                                },
                            ]}
                            getValueFromEvent={normFile}
                        >
                            <Upload
                                accept="image/*"
                                name="logo"
                                listType="picture"
                                beforeUpload={() => false}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Upload
                            </Button>
                            <Button onClick={() => navigate("/banner")} style={{ marginLeft: 8 }}>
                                Cancel
                            </Button>
                            <Button onClick={() => navigate("/banner")} style={{ marginLeft: 8 }}>
                                Back
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </StyledForm>
        </>
    );
};

export default UploadBanner;
