import { createSlice } from "@reduxjs/toolkit";

const permissionSlice = createSlice({
    name: "permission",
    initialState: [],
    reducers: {
        permissionsList: (state, action) => {
            return action.payload
        },
    },
});

export const { permissionsList } = permissionSlice.actions;
export default permissionSlice.reducer;
