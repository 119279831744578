import React, { useState, useEffect } from "react";
import { Switch, Form, Input, Row, Button, Col } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetRoleByIdMutation,
  useUpdateRoleMutation,
} from "../../common/services/roles";
import {
  PermissionList,
  PermissionName,
  StyledCard,
  PermisionLabel,
  StyledForm,
} from "./Roles.styles";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const EditRole = () => {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [form] = Form.useForm();
  const [updateRole] = useUpdateRoleMutation();
  const [editRoleId, { data: tempValue }] = useGetRoleByIdMutation();
  const initialRole = useSelector((state) => state.intialRoleList)


  const [roleName, setRoleName] = useState("");
  const [permissions, setPermissions] = useState(initialRole?.permissions);

  useEffect(() => {
    const userByRoleId = {
      id: roleId,
    };
    editRoleId(userByRoleId);
  }, [roleId]);

  useEffect(() => {
    if (tempValue && tempValue.roles && tempValue.roles.length > 0) {
      const role = tempValue.roles[0];

      form.setFieldsValue({
        roleName: role.name,
      });

      setRoleName(role.name);

      const newPermissions = {};
      Object.keys(role.permissions).forEach((category) => {
        newPermissions[category] = { ...role.permissions[category] };
      });
      setPermissions(newPermissions);
    }
  }, [tempValue]);

  const handlePermissionToggle = (category, action) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [category]: {
        ...prevPermissions[category],
        [action]: !prevPermissions[category][action],
      },
    }));
  };

  const handleSaveRole = async () => {
    if (roleName) {
      const roleData = {
        id: roleId,
        name: roleName,
        permissions: { ...permissions },
      };

      try {
        const response = await updateRole(roleData);
        if (response.data) {
          toast.success(response.data.message)
        }
        if (response.error) {
          toast.error(response.error.data.message)
        }
        navigate(`/role`);
      } catch (error) {
        console.error("Save role error:", error);
      }
    }
  };

  return (
    <div>
      <h1>Edit Role</h1>
      <StyledForm form={form} initialValues={{ roleName }}>
        <Row>
          <Form.Item
            name="roleName"
            label="Role Name"
            rules={[{ required: true, message: "Please enter your role name" }]}
          >
            <Input onChange={(e) => setRoleName(e.target.value)} />
          </Form.Item>
        </Row>
        <br />
        {Object.keys(permissions).map((category) => (
          <StyledCard key={category} title={category}>
            <PermissionList>
              {Object.keys(permissions[category]).map((action) => (
                <PermissionName key={action}>
                  <PermisionLabel>
                    {action.charAt(0).toUpperCase() + action.slice(1)}:
                  </PermisionLabel>
                  <Switch
                    checked={permissions[category][action]}
                    onChange={() => handlePermissionToggle(category, action)}
                  />
                </PermissionName>
              ))}
            </PermissionList>
          </StyledCard>
        ))}
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={handleSaveRole}>
                Update
              </Button>
              <Button onClick={() => navigate("/role")} style={{ marginLeft: 8 }}>
                Cancel
              </Button>
              <Button onClick={() => navigate("/role")} style={{ marginLeft: 8 }}>
                Back
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledForm>
    </div>
  );
};

export default EditRole;
