import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const mediumApi = createApi({
  reducerPath: "mediumApi",
  baseQuery: fetchBaseQuery({
    // baseUrl: `http://localhost:8001/`,
    baseUrl: `${process.env.REACT_APP_BASE_URL}/v1/userservice`,
    validateStatus: (response) => {
      if (response.status === 401) {
        window.location.href = "/login";
        return false;
      } else if (response.status >= 200 && response.status < 300) {
        return true;
      } else {
        throw new Error(`HTTP error ${response.status}`);
      }
    },
  }),
  endpoints: (builder) => ({
    getAllMedium: builder.mutation({
      query: (body) => ({
        url: "api/user/getActiveMediums",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    createMedium: builder.mutation({
      query: (body) => ({
        url: "api/user/createMedium",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    updateMedium: builder.mutation({
      query: (body) => ({
        url: "api/user/updateMedium",
        method: "PUT",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    deleteMedium: builder.mutation({
      query: (body) => ({
        url: "api/user/deactivateMedium",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllMediumMutation,
  useCreateMediumMutation,
  useUpdateMediumMutation,
  useDeleteMediumMutation,
} = mediumApi;
