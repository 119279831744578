import React, { useEffect } from "react";
import { Form, Input, Button, Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetUserByIdMutation,
  useUpdateUserMutation,
} from "../../common/services/users";
import { StyledForm } from "./UserList.styles";
// import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { validationCharacters } from "../../constants";

// const { Option } = Select;

const EditUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  // const user = useSelector((state) => state.userDetails)
  // const roleList = useSelector((state) => state.roleList)

  const [editUserId, { data: tempValue }] = useGetUserByIdMutation();
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    const userById = {
      id: id,
    };
    editUserId(userById);
  }, [id]);

  useEffect(() => {
    if (tempValue && tempValue.User) {
      const { fname, lname, phone, email, school, role, password } =
        tempValue.User;

      form.setFieldsValue({
        fname: fname || "",
        lname: lname || "",
        phone: phone || "",
        email: email || "",
        school: school ? school._id : undefined,
        role: role ? role._id : undefined,
        password: password || "",
        confirmPassword: password || "",
      });
    }
  }, [form, tempValue]);

  const onFinish = async (values) => {
    const { fname, lname, phone } = values;

    const formData = new FormData();
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("phone", phone);
    formData.append("id", id);
    // formData.append("role", role);

    try {
      const response = await updateUser(formData);
      if (response.data) {
        toast.success(response.data.message)
      }
      if (response.error) {
        toast.error(response.error.data.message)
      }
      navigate(`/user`);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const validatePhone = (rule, value, callback) => {
    const phoneRegex = /^\d{10}$/;
    if (!value || !value.match(phoneRegex)) {
      callback('Please enter a valid phone number');
    } else {
      callback();
    }
  };

  return (
    <>
      <h2>Edit User</h2>
      <StyledForm form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="fname"
              label="First Name"
              required
              rules={[
                { required: false, message: "Please enter your first name" },
                { validator: (rule, value, callback) => validationCharacters(rule, value, callback, "Please enter a valid first name") },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="lname"
              label="Last Name"
              required
              rules={[
                { required: false, message: "Please enter your last name" },
                { validator: (rule, value, callback) => validationCharacters(rule, value, callback, "Please enter a valid last name") },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="phone"
              label="Phone"
              required
              rules={[
                { required: false, message: 'Please enter your phone number' },
                { validator: validatePhone },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        {/* <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="school"
              label="School"
              rules={[{ required: true, message: "Please select your school" }]}
            >
              {user?.school && (
                <Select placeholder="Select a School">
                  <Option value={user.school._id} key={user.school._id} >
                    {user.school.schoolName}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: "Please select your role" }]}
            >
              <Select placeholder="Select a role">
                {roleList?.map((item) => (
                  <Option value={item._id} key={item._id}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row> */}

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
              <Button onClick={() => navigate("/user")} style={{ marginLeft: 8 }}>
                Cancel
              </Button>
              <Button onClick={() => navigate("/user")} style={{ marginLeft: 8 }}>
                Back
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledForm >
    </>
  );
};

export default EditUser;
