/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { PageHeader } from "./Question.styles";
import { Button, Col, Input, Row, Select } from "antd";
import {
  useDeleteQuestionMutation,
  useGetAllQuestionMutation,
} from "../../common/services/question";
import { useSelector } from "react-redux";
import UserModal from "../../components/Modal";
import { toast } from "react-toastify";
import PermissionChecker from "../../components/PermissionChecker";
import sampleQuestionExcel from "../../assets/questionUpload.xlsx";
import MathJaxComponent from "../../components/MathJax/MathJaxComponent";
import axios from "axios";

const { Option } = Select;

const Question = () => {
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const user = useSelector((state) => state.userDetails);
  const getMaster = useSelector((state) => state.getAllMaster);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tempDeleteData, setTempDeleteData] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    schoolId: user?.school?._id,
  });

  // console.log(getMaster?.standards, "getMaster standard check");

  const [filteredStandards, setFilteredStandards] = useState(
    getMaster?.standards
  );
  const [filteredSubjects, setFilteredSubjects] = useState(getMaster?.subjects);
  const [filteredChapters, setFilteredChapters] = useState(getMaster?.chapters);
  const [filteredSections, setFilteredSections] = useState(getMaster?.sections);

  const [getAllQuestion, { data, isLoading }] = useGetAllQuestionMutation();
  const [deleteQuestion] = useDeleteQuestionMutation();

  useEffect(() => {
    if (user.school) {
      handleCallGetAPI();
    }
  }, [user, filters]);

  const handleCallGetAPI = () => {
    getAllQuestion(filters);
  };

  // console.log(filters, "filters check");
  // console.log(getMaster, "getMaster check");
  // console.log(filteredStandards, "filteredStandards check");

  const handleFilterChange = (value, key) => {
    // setFilters({
    //   [key]: value,
    // });
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));

    // test purpose for filters
    if (key === "mediumId") {
      const standards = getMaster.standards.filter(
        (standard) => standard.medium === value
      );
      setFilteredStandards(standards);
      setFilteredSubjects([]);
      setFilteredChapters([]);
      setFilteredSections([]);
    } else if (key === "standardId") {
      const subjects = getMaster.subjects.filter(
        (subject) => subject.standard === value
      );
      setFilteredSubjects(subjects);
      setFilteredChapters([]);
      setFilteredSections([]);
    } else if (key === "subjectId") {
      const chapters = getMaster.chapters.filter(
        (chapter) => chapter.subject === value
      );
      setFilteredChapters(chapters);
      setFilteredSections([]);
    } else if (key === "chapterId") {
      const sections = getMaster.sections.filter(
        (section) => section.chapter === value
      );
      setFilteredSections(sections);
    }
  };

  const handleClearFilters = () => {
    setFilters({
      schoolId: user?.school?._id,
    });

    setFilteredStandards(getMaster?.standards);
    setFilteredSubjects(getMaster?.subjects);
    setFilteredChapters(getMaster?.chapters);
    setFilteredSections(getMaster?.sections);
  };

  const containsLatex = (text) => {
    console.log(text, "text check");
    return /\$.*?\$|\[.*?\]|\(.*?\)/.test(text);
  };

  // Function to process questions, rendering LaTeX if present
  const processQuestionsForLatex = (questions) => {
    console.log(questions, "questions check");
    return questions.map((question) => {
      if (containsLatex(question.question)) {
        return {
          ...question,
          question: <MathJaxComponent text={question.question} />, // Render LaTeX using MathJaxComponent
        };
      } else {
        return question; // No LaTeX, return the question unchanged
      }
    });
  };

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
    },
    {
      title: "Medium",
      dataIndex: "medium",
      key: "medium",
      render: (medium) => <>{medium?.mediumName}</>,
    },
    {
      title: "Standard",
      dataIndex: "standard",
      key: "standard",
      render: (standard) => <>{standard?.standardName}</>,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (subject) => <>{subject?.subjectName}</>,
    },
    {
      title: "Chapter",
      dataIndex: "chapter",
      key: "chapter",
      render: (chapter) => <>{chapter?.chapterName}</>,
    },
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
      render: (section) => <>{section?.sectionName}</>,
    },
  ];

  const handleCreateQuestionClick = () => {
    navigate(`/question/create`);
  };

  const handleEdit = (question) => {
    navigate(`/question/edit/${question._id}`);
  };

  const handleDelete = (user) => {
    setDeleteModalVisible(true);
    setTempDeleteData(user);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file, "file check");

    if (file) {
      createQuestionByExcelFile(file);
    }
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    setDeleteModalVisible(false);
    const questionId = {
      id: tempDeleteData._id,
    };

    try {
      const response = await deleteQuestion(questionId);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      handleCallGetAPI();
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const createQuestionByExcelFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/v1/contentservice/api/question/createQuesFromExcel`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      console.log(response, "response check create question by excel");
      toast.success("Questions created successfully by excel file");
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error);
    }
  };

  const actions = [
    {
      key: "edit",
      icon: <EditFilled />,
      onClick: handleEdit,
      label: "Edit",
      style: { backgroundColor: "#7c18ff" },
      permission: { resource: "content", action: "update" },
    },
    {
      key: "delete",
      icon: <DeleteFilled />,
      onClick: handleDelete,
      label: "Delete",
      style: { backgroundColor: "#ff1818" },
      permission: { resource: "content", action: "delete" },
    },
  ];

  const filteredQuestions = data?.questions.filter(
    (question) =>
      question.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
      question.standard.standardName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      question.subject.subjectName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      question.chapter.chapterName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      question.section.sectionName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      question.medium.mediumName
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <h2 style={{ paddingLeft: "5px" }}>Question List</h2>
      <PageHeader>
        <Input
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ maxWidth: "200px" }}
        />
        <div style={{ display: "flex", gap: 5 }}>
          <PermissionChecker resource="content" action="create">
            <Button onClick={handleCreateQuestionClick}>Create Question</Button>
          </PermissionChecker>
          <PermissionChecker resource="content" action="export">
            <input
              type="file"
              accept=".xlsx"
              style={{ display: "none" }}
              ref={inputRef}
              onChange={handleFileChange}
            />
            <Button onClick={() => inputRef.current.click()}>
              Create Question By Excel
            </Button>
          </PermissionChecker>
          <PermissionChecker resource="content" action="import">
            <a
              href={sampleQuestionExcel}
              download={sampleQuestionExcel}
              style={{ textDecoration: "none" }}
            >
              <Button>Download Sample Excel</Button>
            </a>
          </PermissionChecker>
        </div>
      </PageHeader>
      <div style={{ marginBottom: 10, padding: "0 5px" }}>
        <Row gutter={24}>
          <Col span={4}>
            <Select
              placeholder="Medium"
              value={filters?.mediumId}
              onChange={(value) => handleFilterChange(value, "mediumId")}
              style={{ width: 150, display: "inline" }}
            >
              {getMaster?.mediums?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.mediumName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              placeholder="Standard"
              value={filters?.standardId}
              onChange={(value) => handleFilterChange(value, "standardId")}
              style={{ width: 150, display: "inline" }}
            >
              {filteredStandards?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.standardName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              placeholder="Subject"
              value={filters?.subjectId}
              onChange={(value) => handleFilterChange(value, "subjectId")}
              style={{ width: 150, display: "inline" }}
            >
              {filteredSubjects?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.subjectName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              placeholder="Chapter"
              value={filters?.chapterId}
              onChange={(value) => handleFilterChange(value, "chapterId")}
              style={{ width: 150, display: "inline" }}
            >
              {filteredChapters?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.chapterName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              placeholder="Section"
              value={filters?.sectionId}
              onChange={(value) => handleFilterChange(value, "sectionId")}
              style={{ width: 150, display: "inline" }}
            >
              {filteredSections?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.sectionName}
                </Option>
              ))}
            </Select>
          </Col>
          <Button onClick={handleClearFilters} style={{ marginLeft: 10 }}>
            Clear Filters
          </Button>
        </Row>
      </div>
      <DataTable
        dataSource={
          filteredQuestions &&
          [...filteredQuestions].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        }
        columns={columns}
        actions={actions}
        loading={isLoading}
      />

      {/* <DataTable
        dataSource={
          filteredQuestions &&
          processQuestionsForLatex(
            [...filteredQuestions].sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            )
          )
        }
        columns={columns}
        actions={actions}
        loading={isLoading}
      /> */}
      <UserModal
        visible={deleteModalVisible}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={"Delete Question"}
        description={"Are you sure you want to delete this question?"}
      />
    </>
  );
};

export default Question;
