import { Button, Checkbox, Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import styled from "styled-components";

export const StyledLoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ffffff;
`;

export const StyledLoginBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
`;

export const StyledLoginForm = styled(Form)`
  flex: 1 0 100%;
  max-width: 480px;
  width: 100%;
  padding: 60px;
`;

export const StyledFormTitle = styled.p`
  margin-bottom: 30px;
  margin-top: 0;
  color: #333333;
  font-family: "Josefin Sans", sans-serif;
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
`;

export const StyledFormItem = styled(FormItem)`
  text-align: left;

  .ant-form-item-label > label.ant-form-item-required::before {
    display: none;
  }
`;

export const StyledInput = styled(Input)`
  padding: 12px 15px;
  height: 48px;
`;

export const StyledPasswordInput = styled(Input.Password)`
  padding: 12px 15px;
  height: 48px;
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: #335ddc;
    border-radius: 8px;
  }
`;

export const StyledButton = styled(Button)`
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
  width: 100%;
`;

export const StyledIllustrationWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: #fffdf2;
`;

export const StyledIllustration = styled.img`
  display: block;
  width: 100%;
`;
