import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Col, Row, Select, Button } from "antd";
import { useCreateStandardMutation } from "../../common/services/standard";
import { StyledForm } from "./Standard.styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { validationCharactersNumber } from "../../constants";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useGetAllMasterMutation } from "../../common/services/schools";
import { getAllMaster } from "../../common/features/schoolSlice";

const CreateStandard = () => {
  const { Option } = Select;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const user = useSelector((state) => state.userDetails);
  const [getMasterDetails, { data: getMaster }] = useGetAllMasterMutation();

  const data = useSelector((state) => state.getAllMaster);
  const [createStandardMute] = useCreateStandardMutation();

  console.log(data, "medium data check");

  useEffect(() => {
    if (user?.school) {
      form.setFieldsValue({
        school: user.school._id,
      });
    }

    getMasterDetails({ schoolId: user?.school?._id });
    dispatch(getAllMaster(getMaster));
  }, [user, form]);

  const onFinish = async (values) => {
    const { standardName, school, description, medium } = values;

    const formData = {
      standardName,
      school,
      description,
      medium,
    };

    try {
      const response = await createStandardMute(formData);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      navigate(`/standard`);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <>
      {/* <Button size="small" onClick={() => navigate("/standard")}> */}
      <ArrowLeftOutlined
        onClick={() => navigate("/standard")}
        className="cursor-pointer"
      />
      {/* </Button> */}
      <h2>Create Standard</h2>
      <StyledForm form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="standardName"
              label="Standard Name"
              required
              rules={[
                {
                  required: false,
                  message: "Please enter your standard name",
                },
                { validator: validationCharactersNumber },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="description" label="Description">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="school"
              label="School"
              rules={[{ required: true, message: "Please select your school" }]}
            >
              {user?.school && (
                <Select placeholder="Select a School" disabled>
                  <Option value={user.school._id} key={user.school._id}>
                    {user.school.schoolName}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="medium"
              label="Medium"
              rules={[{ required: true, message: "Please select your medium" }]}
            >
              <Select placeholder="Select a Medium">
                {data?.mediums?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.mediumName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
              <Button
                onClick={() => navigate("/standard")}
                style={{ marginLeft: 8 }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledForm>
    </>
  );
};

export default CreateStandard;
