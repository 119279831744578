import styled from "styled-components";
import { Form } from "antd";

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  margin-bottom: 10px;
`;

export const StyledForm = styled(Form)`
  margin: 0 auto;
  padding: 50px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

export const OptionWrapper = styled.div`
  padding: 20px;
  border: 1px solid #c7c2c2;
  margin-bottom: 15px;
  border-radius: 5px;
`;

export const TransferWrapper = styled.div`
  display: flex;
`;

export const MarkWrapper = styled.div`
  display: flex;
  height: 300px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
`;

export const MarkHeading = styled.div`
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  color: rgba(0, 0, 0, 0.88);
  background: #ffffff;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  border-radius: 8px 8px 0 0;
`;

export const PositiveMark = styled.div`
  border-right: 1px solid #d9d9d9;
  overflow: auto;
`;
