import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const questionApi = createApi({
  reducerPath: "questionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/v1/contentservice`,
    // baseUrl: `http://localhost:8002/`,
    validateStatus: (response) => {
      if (response.status === 401) {
        window.location.href = "/login";
        return false;
      } else if (response.status >= 200 && response.status < 300) {
        return true;
      } else {
        throw new Error(`HTTP error ${response.status}`);
      }
    },
  }),
  endpoints: (builder) => ({
    getAllQuestion: builder.mutation({
      query: (body) => ({
        url: "api/question/getQuestionsAdminPanel",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    createQuestion: builder.mutation({
      query: (body) => ({
        url: "api/question/createQuestion",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    updateQuestion: builder.mutation({
      query: (body) => ({
        url: "api/question/updateQuestion",
        method: "PUT",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    deleteQuestion: builder.mutation({
      query: (body) => ({
        url: "api/question/deleteQuestions",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllQuestionMutation,
  useCreateQuestionMutation,
  useUpdateQuestionMutation,
  useDeleteQuestionMutation,
} = questionApi;
