import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Select, Row, Col, Transfer, Spin } from "antd";
import {
  MarkHeading,
  MarkWrapper,
  OptionWrapper,
  PositiveMark,
  StyledForm,
  TransferWrapper,
} from "./Test.styles";
import { useDispatch, useSelector } from "react-redux";
import { masterContentSeletedValue } from "../../common/features/contentSlice";
import { useGetAllQuestionMutation } from "../../common/services/question";
import { useCreateTestMutation } from "../../common/services/test";
import { toast } from "react-toastify";
import {
  validationCharactersAlphabets,
  validationOnlyNumbers,
} from "../../constants";
import { ArrowLeftOutlined } from "@ant-design/icons";

const CreateTest = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [testLoader, setTestLoader] = useState(false);

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [marks, setMarks] = useState({});

  const [createTestMute] = useCreateTestMutation();
  const [getAllQuestion, { data: getAllQuestionList }] =
    useGetAllQuestionMutation();

  const data = useSelector((state) => state.getAllMaster);
  const user = useSelector((state) => state.userDetails);
  const masterContent = useSelector((state) => state.masterContentSeletedValue);

  // console.log(user,'user check creat test')

  useEffect(() => {
    const chapterId = masterContent.filter((item) => item.type === "chapterId");
    if (chapterId.length) {
      // const currentSectionId = {
      //   sectionId: sectionId[0]?.value,
      // };
      const currentChapterId = {
        chapterId: chapterId[0]?.value,
        schoolId: user?.school?._id,
      };
      getAllQuestion(currentChapterId);
    }
  }, [masterContent]);

  // console.log(masterContent, " all questions list check");

  useEffect(() => {
    if (user?.school) {
      form.setFieldsValue({
        schoolId: user.school._id,
      });
    }
    if (user?.school && user?.school?.board.length > 0) {
      form.setFieldsValue({
        boardId: user.school.board[0]._id,
      });
    }
  }, [user, form]);

  const handleSelectionChange = (type, value) => {
    const newSelection = { type, value };
    dispatch(masterContentSeletedValue(newSelection));

    if (type === "mediumId") {
      const standard = data?.standards?.filter(
        (standard) => standard.medium === value
      );
      setFilteredData((prevData) => ({ ...prevData, standard }));
    }
    if (type === "standardId") {
      const subject = data?.subjects?.filter(
        (subject) => subject.standard === value
      );
      setFilteredData((prevData) => ({ ...prevData, subject }));
    }
    if (type === "subjectId") {
      const chapter = data?.chapters?.filter(
        (chapter) => chapter.subject === value
      );
      setFilteredData((prevData) => ({ ...prevData, chapter }));
    }
    if (type === "chapterId") {
      const section = data?.sections?.filter(
        (section) => section.chapter === value
      );
      setFilteredData((prevData) => ({ ...prevData, section }));
    }
  };

  const handleClickApply = () => {
    setIsFormVisible(true);
  };

  const handleChange = (nextTargetKeys) => {
    setSelectedKeys(nextTargetKeys);
  };

  const handleMarkChange = (value, key) => {
    setMarks((prevMarks) => ({ ...prevMarks, [key]: value }));
  };

  const onFinish = async (values) => {
    setTestLoader(true);
    const {
      title,
      description,
      totalMarks = 10,
      totalQuestions = 10,
      randamizeQuestions = 0,
      passingPercentage,
    } = values;

    const formData = new FormData();
    masterContent.map((item) => {
      formData.append(item.type, item.value);
    });
    formData.append("title", title);
    formData.append("description", description);
    formData.append("totalMarks", totalMarks);
    formData.append("totalQuestions", totalQuestions);
    formData.append("randamizeQuestions", randamizeQuestions);
    formData.append("passingPercentage", passingPercentage);

    selectedKeys.forEach((key, index) => {
      formData.append(`questions[${index}][question]`, key);
      formData.append(`questions[${index}][mark]`, marks[key] || "");
    });

    formData.append("schoolId", user?.school?._id);
    formData.append("boardId", user?.school?.board[0]?._id);

    try {
      const response = await createTestMute(formData);
      if (response.data) {
        setTestLoader(false);
        toast.success(response.data.message);
      }
      if (response.error) {
        setTestLoader(false);
        toast.error(response.error.data.message);
      }
      navigate(`/test`);
    } catch (error) {
      setTestLoader(false);
      console.error("Login error:", error);
    }
  };

  return (
    <>
      {/* <Button size="small" onClick={() => navigate("/test")}> */}
      <ArrowLeftOutlined
        onClick={() => navigate("/test")}
        className="cursor-pointer"
      />
      {/* </Button> */}
      <h2>Create Test</h2>
      <StyledForm form={form} onFinish={handleClickApply} layout="vertical">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="boardId"
              label="Board"
              rules={[{ required: true, message: "Please select your board" }]}
            >
              {user?.school && (
                <Select
                  placeholder="Select a board"
                  onChange={(value) => handleSelectionChange("boardId", value)}
                  disabled
                >
                  {user?.school?.board.map((item) => (
                    <Option key={item?._id} value={item?._id}>
                      {item?.boardName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="schoolId"
              label="School"
              rules={[{ required: true, message: "Please select your school" }]}
            >
              {user?.school && (
                <Select
                  placeholder="Select a School"
                  onChange={(value) => handleSelectionChange("schoolId", value)}
                  disabled
                >
                  <Option value={user.school._id} key={user.school._id}>
                    {user.school.schoolName}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="mediumId"
              label="Medium"
              rules={[{ required: true, message: "Please select your medium" }]}
            >
              <Select
                placeholder="Select a Medium"
                onChange={(value) => handleSelectionChange("mediumId", value)}
              >
                {data?.mediums?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.mediumName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="standardId"
              label="Standard"
              rules={[
                { required: true, message: "Please select your standard" },
              ]}
            >
              <Select
                placeholder="Select a Standard"
                onChange={(value) => handleSelectionChange("standardId", value)}
                disabled={!form.getFieldValue("mediumId")}
              >
                {filteredData?.standard?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.standardName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="subjectId"
              label="Subject"
              rules={[
                { required: true, message: "Please select your subject" },
              ]}
            >
              <Select
                placeholder="Select a Subject"
                onChange={(value) => handleSelectionChange("subjectId", value)}
                disabled={!form.getFieldValue("standardId")}
              >
                {filteredData?.subject?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.subjectName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="chapterId"
              label="Chapter"
              rules={[
                { required: true, message: "Please select your chapter" },
              ]}
            >
              <Select
                placeholder="Select a Chapter"
                onChange={(value) => handleSelectionChange("chapterId", value)}
                disabled={!form.getFieldValue("subjectId")}
              >
                {filteredData?.chapter?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.chapterName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="sectionId"
              label="Section"
              rules={[
                { required: true, message: "Please select your section" },
              ]}
            >
              <Select
                placeholder="Select a Section"
                onChange={(value) => handleSelectionChange("sectionId", value)}
                disabled={!form.getFieldValue("chapterId")}
              >
                {filteredData?.section?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.sectionName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            style={{ backgroundColor: "#1f292b" }}
            type="primary"
            htmlType="submit"
          >
            Apply
          </Button>
        </Form.Item>
      </StyledForm>
      {isFormVisible && (
        <>
          <Spin spinning={testLoader} fullscreen />
          <StyledForm form={form} onFinish={onFinish} layout="vertical">
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="title"
                  label="Title"
                  required
                  rules={[
                    {
                      required: false,
                      message: "Please enter title",
                    },
                    {
                      validator: (rule, value, callback) =>
                        validationCharactersAlphabets(
                          rule,
                          value,
                          callback,
                          "Please enter a valid title"
                        ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="description" label="Description">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="totalMarks"
                  label="Total Marks"
                  required
                  rules={[
                    {
                      required: false,
                      message: "Please enter total marks",
                    },
                    {
                      validator: (rule, value, callback) =>
                        validationOnlyNumbers(
                          rule,
                          value,
                          callback,
                          "Please enter a valid total marks"
                        ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="totalQuestions"
                  label="Total Questions"
                  required
                  rules={[
                    {
                      required: false,
                      message: "Please enter total questions",
                    },
                    {
                      validator: (rule, value, callback) =>
                        validationOnlyNumbers(
                          rule,
                          value,
                          callback,
                          "Please enter a valid total questions"
                        ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="randamizeQuestions"
                  label="Randamize Questions"
                  required
                  rules={[
                    {
                      required: false,
                      message: "Please enter randamize questions",
                    },
                    {
                      validator: (rule, value, callback) =>
                        validationOnlyNumbers(
                          rule,
                          value,
                          callback,
                          "Please enter a valid randamize questions"
                        ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row> */}
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="passingPercentage"
                  label="Passing Percentage"
                  required
                  rules={[
                    {
                      required: false,
                      message: "Please enter passing percentage",
                    },
                    {
                      validator: (rule, value, callback) =>
                        validationOnlyNumbers(
                          rule,
                          value,
                          callback,
                          "Please enter a valid passing percentage"
                        ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <OptionWrapper>
              <div>
                <TransferWrapper>
                  <Transfer
                    dataSource={getAllQuestionList?.questions.map(
                      (question, index) => ({
                        key: question._id,
                        question: `(${index + 1}). ${question.question.replace(
                          /<[^>]+>/g,
                          ""
                        )}`,
                      })
                    )}
                    targetKeys={selectedKeys}
                    listStyle={{
                      width: 300,
                      height: 300,
                    }}
                    onChange={handleChange}
                    render={(item) => item.question}
                  />
                  <MarkWrapper>
                    <PositiveMark>
                      <MarkHeading>Marks</MarkHeading>
                      {selectedKeys.map((key, index) => (
                        <div key={key}>
                          <Form.Item
                            style={{ marginBottom: "0" }}
                            name={["marks", key]}
                            required
                            rules={[
                              {
                                required: false,
                                message: "Please enter mark",
                              },
                              // {
                              //   validator: (rule, value, callback) =>
                              //     validationOnlyNumbers(
                              //       rule,
                              //       value,
                              //       callback,
                              //       "Please enter a valid mark"
                              //     ),
                              // },
                            ]}
                          >
                            <span style={{ marginLeft: "5px" }}>
                              {`(${index + 1})`}
                            </span>
                            <Input
                              style={{
                                margin: "2px 10px",
                                fontSize: "10px",
                                width: "40px",
                                padding: "5px",
                              }}
                              placeholder="Marks"
                              value={marks[key] || ""}
                              onChange={(e) =>
                                handleMarkChange(e.target.value, key)
                              }
                            />
                          </Form.Item>
                        </div>
                      ))}
                    </PositiveMark>
                    <div></div>
                  </MarkWrapper>
                </TransferWrapper>
              </div>
            </OptionWrapper>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Create
                  </Button>
                  <Button
                    onClick={() => navigate("/test")}
                    style={{ marginLeft: 8 }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </StyledForm>
        </>
      )}
    </>
  );
};

export default CreateTest;
