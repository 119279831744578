import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import { DeleteFilled, EditFilled, UserOutlined } from "@ant-design/icons";
import { PageHeader, SchoolLogo } from "./Student.styles";
import UserModal from "../../components/Modal";
import { Avatar, Button, Input } from "antd";
import { toast } from "react-toastify";
import PermissionChecker from "../../components/PermissionChecker";
import {
  useDeleteStudentMutation,
  useGetAllStudentsQuery,
} from "../../common/services/student";
import { useSelector } from "react-redux";
import axios from "axios";
import sampleStudentExcel from "../../assets/student.xlsx";

const Student = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.userDetails);
  const inputRef = useRef(null);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tempDeleteData, setTempDeleteData] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const formData = new FormData();

  const { data, error, isLoading, refetch } = useGetAllStudentsQuery(
    user?.school?._id,
    { refetchOnMountOrArgChange: true }
  );
  const [deleteStudent] = useDeleteStudentMutation();

  if (error) {
    toast.error(error?.message);
  }

  // console.log(user, "user data check");
  // console.log(data, "data check student indecx file");

  const columns = [
    {
      title: "Profile Picture",
      dataIndex: "profilePicture",
      key: "profilePicture",

      render: (logo) => (
        <>
          {logo ? (
            <SchoolLogo
              src={`https://ilearnbackend.s3.ap-south-1.amazonaws.com/${logo?.path}`}
              alt="Subject Logo"
            />
          ) : (
            <Avatar size="large" icon={<UserOutlined />} />
          )}
        </>
      ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Student ID",
      dataIndex: "studentId",
      key: "studentId",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      searchable: true,
    },
    {
      title: "Board",
      dataIndex: "board",
      key: "board",
      render: (board) => <>{board?.boardName}</>,
    },
    {
      title: "Medium",
      dataIndex: "medium",
      key: "medium",
      render: (medium) => <>{medium?.mediumName}</>,
    },
    {
      title: "Standard",
      dataIndex: "standard",
      key: "standard",
      render: (standard) => <>{standard?.standardName}</>,
    },
    {
      title: "School",
      dataIndex: "school",
      key: "school",
      render: (school) => <>{school?.schoolName}</>,
    },
  ];

  const handleCreateStudentClick = () => {
    navigate(`/student/create`);
  };

  const handleEdit = (student) => {
    navigate(`/student/edit/${student._id}`);
  };

  const handleDelete = (student) => {
    setDeleteModalVisible(true);
    setTempDeleteData(student);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      createStudentByExcelFile(file);
    }
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    setDeleteModalVisible(false);
    const studentId = {
      studentId: tempDeleteData._id,
    };

    try {
      const response = await deleteStudent(studentId);
      if (response.data) {
        toast.success(response.data.message);
        refetch();
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const createStudentByExcelFile = async (file) => {
    try {
      formData.append("file", file);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/userservice/api/student/createStudentByExcel`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      console.log(response, "response check create student by excel");
      toast.success("Student created successfully by excel file");
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error);
    }
  };

  const downloadStudentExcel = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/userservice/api/student/exportStudentData`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // Create an anchor element and trigger a download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "students.xlsx"); // Set the file name
      document.body.appendChild(link);
      link.click(); // Trigger the download
      // Clean up by revoking the Object URL
      window.URL.revokeObjectURL(url);
      // console.log(response, "response check download student excel");
    } catch (error) {
      console.log(error);
    }
  };

  const actions = [
    {
      key: "edit",
      icon: <EditFilled />,
      onClick: handleEdit,
      label: "Edit",
      style: { backgroundColor: "#7c18ff" },
      permission: { resource: "student", action: "update" },
    },
    {
      key: "delete",
      icon: <DeleteFilled />,
      onClick: handleDelete,
      label: "Delete",
      style: { backgroundColor: "#ff1818" },
      permission: { resource: "student", action: "delete" },
    },
  ];

  const filteredStudent =
    data?.students &&
    data?.students.filter(
      (student) =>
        student?.firstName
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase()) ||
        student?.lastName
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase()) ||
        student?.email?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        student?.phone?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );

  return (
    <>
      <h2 style={{ paddingLeft: "5px" }}>Student List</h2>
      <PageHeader>
        <Input
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ maxWidth: "200px" }}
        />
        <div style={{ display: "flex", gap: 5 }}>
          <PermissionChecker resource="student" action="create">
            <Button onClick={handleCreateStudentClick}>Create Student</Button>
          </PermissionChecker>
          <PermissionChecker resource="student" action="import">
            <input
              type="file"
              accept=".xlsx"
              style={{ display: "none" }}
              ref={inputRef}
              onChange={handleFileChange}
            />
            <Button onClick={() => inputRef.current.click()}>
              Create Student By Excel
            </Button>
          </PermissionChecker>
          <PermissionChecker resource="student" action="import">
            <a
              href={sampleStudentExcel}
              download={sampleStudentExcel}
              style={{ textDecoration: "none" }}
            >
              <Button>Download Sample Excel File</Button>
            </a>
          </PermissionChecker>
          <PermissionChecker resource="student" action="export">
            <Button onClick={downloadStudentExcel}>
              Download Students Excel File
            </Button>
          </PermissionChecker>
        </div>
      </PageHeader>
      <DataTable
        dataSource={
          filteredStudent &&
          [...filteredStudent].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        }
        columns={columns}
        actions={actions}
        loading={isLoading}
      />
      <UserModal
        visible={deleteModalVisible}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={"Delete Student"}
        description={"Are you sure you want to delete this student?"}
      />
    </>
  );
};

export default Student;
