/* eslint-disable no-unused-vars */
import React from "react";
import { Card, Col, Row, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import EChart from "../Chart/EChart";
import LineChart from "../Chart/LineChart";
import {
  AddRoleIcon,
  AddSchoolIcon,
  AddSubjectIcon,
  AddUserIcon,
  ChartCol,
  Title,
} from "./Dashboard.styles";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const { Text } = Typography;

  const count = [
    {
      today: "Users",
      title: "53",
      icon: <AddUserIcon />,
    },
    {
      today: "Schools",
      title: "3",
      icon: <AddSchoolIcon />,
    },
    {
      today: "Subjects",
      title: "18",
      icon: <AddSubjectIcon />,
    },
    {
      today: "Roles",
      title: "10",
      icon: <AddRoleIcon />,
    },
  ];

  return (
    <>
      <div>
        <Row gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col key={index} xs={24} sm={24} md={12} lg={6} xl={6}>
              <Card bordered={false}>
                <div>
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={16}>
                      <span>{c.today}</span>
                      <Title>{c.title}</Title>
                    </Col>
                    <Col xs={6}>
                      <Link to="/">{c.icon}</Link>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row gutter={[24, 0]}>
          <ChartCol xs={24} sm={24} md={12} lg={12} xl={10}>
            <Card bordered={false}>
              <EChart />
            </Card>
          </ChartCol>
          <ChartCol xs={24} sm={24} md={12} lg={12} xl={14}>
            <Card bordered={false}>
              <LineChart />
            </Card>
          </ChartCol>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} md={12} sm={24} lg={12} xl={14}>
            <Card bordered={false}>
              <Row gutter>
                <Col xs={24} md={12} sm={24} lg={12} xl={14}>
                  {/* <div>
                    <div>
                      <Text>Upcoming events</Text>
                      <Title level={5}>
                        The standard Lorem Ipsum passage, used since the 1500s
                      </Title>
                      <Paragraph>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature
                      </Paragraph>
                    </div>
                    <div>
                      <Link to="/">
                        Read More
                        {<RightOutlined />}
                      </Link>
                    </div>
                  </div> */}
                </Col>
                <Col xs={24} md={12} sm={24} lg={12} xl={10}>
                  <div>{/* <img src={card} alt="" /> */}</div>
                </Col>
              </Row>
            </Card>
          </Col>

          {/* <Col xs={24} md={12} sm={24} lg={12} xl={10}>
            <Card bordered={false}>
              <div>
                <div>
                  <Title level={5}>Work with the best</Title>
                  <p>
                    Wealth creation is an evolutionarily recent positive-sum
                    game. It is all about who take the opportunity first.
                  </p>
                </div>
                <div>
                  <Link to="/">
                    Read More
                    <RightOutlined />
                  </Link>
                </div>
              </div>
            </Card>
          </Col> */}
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
