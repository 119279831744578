import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/DataTable";
import { DeleteFilled, EditFilled, EyeOutlined } from "@ant-design/icons";
import {
  useDeleteContentMutation,
  useGetAllContentMutation,
} from "../../common/services/content";
import { PageHeader } from "./Content.styles";
import { Button, Drawer, Input, Col, Row, Select } from "antd";
import { previewContentData } from "../../common/features/contentSlice";
import PreviewContent from "./PreviewContent";
import UserModal from "../../components/Modal";
import { toast } from "react-toastify";
import PermissionChecker from "../../components/PermissionChecker";
import { useGetAllMasterMutation } from "../../common/services/schools";
import { getAllMaster } from "../../common/features/schoolSlice";
const { Option } = Select;

const Content = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userDetails);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tempDeleteData, setTempDeleteData] = useState();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    schoolId: user?.school?._id,
  });
  const [getAllContent, { data, isLoading }] = useGetAllContentMutation();
  const [getMasterDetails, { data: getMaster }] = useGetAllMasterMutation();

  const [filteredStandards, setFilteredStandards] = useState(
    getMaster?.standards
  );
  const [filteredSubjects, setFilteredSubjects] = useState(getMaster?.subjects);
  const [filteredChapters, setFilteredChapters] = useState(getMaster?.chapters);
  const [filteredSections, setFilteredSections] = useState(getMaster?.sections);

  const [deleteContent] = useDeleteContentMutation();

  useEffect(() => {
    if (user.school) {
      handleCallGetAPI();
    }
  }, [user, filters]);

  useEffect(() => {
    dispatch(getAllMaster(getMaster));
  }, [getMaster]);

  const handleCallGetAPI = () => {
    const currentSchoolId = {
      schoolId: user.school._id,
    };
    getAllContent(filters);
    getMasterDetails(currentSchoolId);
  };

  const handleFilterChange = (value, key) => {
    // setFilters({
    //   [key]: value,
    // });
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));

    // test purpose for filters
    if (key === "mediumId") {
      const standards = getMaster.standards.filter(
        (standard) => standard.medium === value
      );
      setFilteredStandards(standards);
      setFilteredSubjects([]);
      setFilteredChapters([]);
      setFilteredSections([]);
    } else if (key === "standardId") {
      const subjects = getMaster.subjects.filter(
        (subject) => subject.standard === value
      );
      setFilteredSubjects(subjects);
      setFilteredChapters([]);
      setFilteredSections([]);
    } else if (key === "subjectId") {
      const chapters = getMaster.chapters.filter(
        (chapter) => chapter.subject === value
      );
      setFilteredChapters(chapters);
      setFilteredSections([]);
    } else if (key === "chapterId") {
      const sections = getMaster.sections.filter(
        (section) => section.chapter === value
      );
      setFilteredSections(sections);
    }
  };

  const handleClearFilters = () => {
    setFilters({
      schoolId: user?.school?._id,
    });

    setFilteredStandards(getMaster?.standards);
    setFilteredSubjects(getMaster?.subjects);
    setFilteredChapters(getMaster?.chapters);
    setFilteredSections(getMaster?.sections);
  };

  const handleClickPreview = (data) => {
    dispatch(previewContentData(data));
    setDrawerVisible(true);
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
      render: (section) => section.sectionName,
    },
    {
      title: "Standard",
      dataIndex: "standard",
      key: "standard",
      render: (standard) => standard.standardName,
    },
    {
      title: "Medium",
      dataIndex: "medium",
      key: "medium",
      render: (medium) => medium.mediumName,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (subject) => subject.subjectName,
    },
    {
      title: "Chapter",
      dataIndex: "chapter",
      key: "chapter",
      render: (chapter) => chapter.chapterName,
    },
  ];

  const handleCreateContentClick = () => {
    navigate(`/content/create`);
  };

  const handleEdit = (content) => {
    navigate(`/content/edit/${content._id}`);
  };

  const handleDelete = (user) => {
    setDeleteModalVisible(true);
    setTempDeleteData(user);
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    setDeleteModalVisible(false);
    const contentId = {
      id: tempDeleteData._id,
    };

    try {
      const response = await deleteContent(contentId);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      handleCallGetAPI();
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const actions = [
    {
      key: "preview",
      icon: <EyeOutlined />,
      onClick: handleClickPreview,
      label: "Preview",
      style: { backgroundColor: "#313131" },
      permission: { resource: "content", action: "read" },
    },
    {
      key: "edit",
      icon: <EditFilled />,
      onClick: handleEdit,
      label: "Edit",
      style: { backgroundColor: "#7c18ff" },
      permission: { resource: "content", action: "update" },
    },
    {
      key: "delete",
      icon: <DeleteFilled />,
      onClick: handleDelete,
      label: "Delete",
      style: { backgroundColor: "#ff1818" },
      permission: { resource: "content", action: "delete" },
    },
  ];

  const filteredContents = data?.contents.filter(
    (content) =>
      content.section.sectionName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      content.standard.standardName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      content.subject.subjectName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      content.chapter.chapterName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      content.medium.mediumName
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <h2 style={{ paddingLeft: "5px" }}>Content List</h2>
      <PageHeader>
        <Input
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ maxWidth: "200px" }}
        />
        <PermissionChecker resource="content" action="create">
          <Button onClick={handleCreateContentClick}>Create Content</Button>
        </PermissionChecker>
      </PageHeader>
      <div style={{ marginBottom: 10, padding: "0 5px" }}>
        <Row gutter={24}>
          <Col span={4}>
            <Select
              placeholder="Medium"
              value={filters?.mediumId}
              onChange={(value) => handleFilterChange(value, "mediumId")}
              style={{ width: 150, display: "inline" }}
            >
              {getMaster?.mediums?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.mediumName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              placeholder="Standard"
              value={filters?.standardId}
              onChange={(value) => handleFilterChange(value, "standardId")}
              style={{ width: 150, display: "inline" }}
            >
              {filteredStandards?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.standardName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              placeholder="Subject"
              value={filters?.subjectId}
              onChange={(value) => handleFilterChange(value, "subjectId")}
              style={{ width: 150, display: "inline" }}
            >
              {filteredSubjects?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.subjectName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              placeholder="Chapter"
              value={filters?.chapterId}
              onChange={(value) => handleFilterChange(value, "chapterId")}
              style={{ width: 150, display: "inline" }}
            >
              {filteredChapters?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.chapterName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              placeholder="Section"
              value={filters?.sectionId}
              onChange={(value) => handleFilterChange(value, "sectionId")}
              style={{ width: 150, display: "inline" }}
            >
              {filteredSections?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.sectionName}
                </Option>
              ))}
            </Select>
          </Col>
          <Button onClick={handleClearFilters} style={{ marginLeft: 10 }}>
            Clear Filters
          </Button>
        </Row>
      </div>
      <DataTable
        dataSource={
          filteredContents &&
          [...filteredContents].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        }
        columns={columns}
        actions={actions}
        loading={isLoading}
      />
      <Drawer
        title="Content Preview"
        placement="right"
        closable={true}
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width={600}
      >
        <PreviewContent />
      </Drawer>
      <UserModal
        visible={deleteModalVisible}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={"Delete Content"}
        description={"Are you sure you want to delete this content?"}
      />
    </>
  );
};

export default Content;
