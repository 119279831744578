import { Card, Form } from "antd";
import styled from "styled-components";

export const PermissionWrapper = styled.div`
  padding: 7px;
  border-radius: 4px;
  border: 1px solid #efefef;
  margin-bottom: 5px;
`;

export const PermissionName = styled.b`
  text-transform: capitalize;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  margin-bottom: 10px;
`;

export const StyledCard = styled(Card)`
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

export const PermissionList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`;

export const PermisionLabel = styled.label`
  margin-right: 5px;
`;

export const StyledForm = styled(Form)`
  margin: 0 auto;
  padding: 50px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;
