import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Col, Row, Select, Button, Upload, Spin } from "antd";
import { useCreateSchoolMutation } from "../../common/services/schools";
import { StyledForm } from "./Schools.styles";
import { useSelector } from "react-redux";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import {
  validationCharacters,
  validationCharactersAlphabets,
  validationCharactersNumber,
} from "../../constants";

const { Option } = Select;
const { TextArea } = Input;

const CreateSchool = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const user = useSelector((state) => state.userDetails);
  const [createSchool, { isLoading }] = useCreateSchoolMutation();

  useEffect(() => {
    if (user?.school && user?.school?.board.length > 0) {
      form.setFieldsValue({
        board: user.school.board[0]._id,
      });
    }
  }, [user, form]);

  const onFinish = async (values) => {
    const { schoolName, email, phone, address, city, board, schoolLogo } =
      values;
    const formData = new FormData();
    formData.append("schoolName", schoolName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("board", board);
    if (schoolLogo && schoolLogo[0] && schoolLogo[0].originFileObj) {
      formData.append("schoolLogo", schoolLogo[0].originFileObj);
    }

    try {
      const response = await createSchool(formData);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      navigate(`/school`);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const validatePhone = (rule, value, callback) => {
    const phoneRegex = /^\d{10}$/;
    if (!value || !value.match(phoneRegex)) {
      callback("Please enter a valid phone number");
    } else {
      callback();
    }
  };

  return (
    <>
      {/* <Button size="small" onClick={() => navigate("/school")}> */}
      <ArrowLeftOutlined
        className="cursor-pointer"
        onClick={() => navigate("/school")}
      />
      {/* </Button> */}
      <h2>Create School</h2>
      <Spin spinning={isLoading} fullscreen />
      <StyledForm form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="schoolName"
              label="School Name"
              required
              rules={[
                {
                  required: false,
                  message: "Please enter your school name",
                },
                { validator: validationCharactersNumber },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="phone"
              label="Phone"
              required
              rules={[
                { required: false, message: "Please enter your phone number" },
                { validator: validatePhone },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="address"
              label="Address"
              required
              rules={[
                { required: false, message: "Please enter your address" },
                { validator: validationCharactersAlphabets },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="city"
              label="City"
              required
              rules={[
                { required: false, message: "Please enter your city" },
                { validator: validationCharacters },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="board"
              label="Board"
              rules={[{ required: true, message: "Please select your board" }]}
            >
              {user?.school && (
                <Select placeholder="Select a board" disabled>
                  {user?.school?.board.map((item) => (
                    <Option key={item?._id} value={item?._id}>
                      {item?.boardName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Col span={6}>
          <Form.Item
            name="schoolLogo"
            label="School Logo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              accept="image/*"
              name="logo"
              listType="picture"
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
              <Button
                onClick={() => navigate("/school")}
                style={{ marginLeft: 8 }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledForm>
    </>
  );
};

export default CreateSchool;
