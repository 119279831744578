import React from 'react';
import { useSelector } from 'react-redux';
import { PreviewContainer } from './Content.styles';
import DataTable from '../../components/DataTable';

const PreviewContent = () => {
    const PreviewData = useSelector((state) => state.previewContentData);
    const { contents } = PreviewData;

    const columns = [
        {
            title: 'Index',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Content',
            dataIndex: 'media',
            key: 'media',
            render: (text, record) => {
                if (record.type === 'media') {
                    if (record.mediafile?.path.toLowerCase().includes('pdf')) {
                        return (
                            <a href={`https://ilearnbackend.s3.ap-south-1.amazonaws.com/${record.mediafile.path}`} target="_blank" rel="noopener noreferrer">
                                View PDF
                            </a>
                        );
                    } else {
                        return (
                            <video controls width={'100%'} height={300}>
                                <source src={`https://ilearnbackend.s3.ap-south-1.amazonaws.com/${record.mediafile.path}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        );
                    }
                } else if (record.type === 'hyperlink') {
                    return (
                        <>
                            <a href={record.hyperlink} target="_blank" rel="noreferrer">{record.hyperlink}</a >
                        </>
                    );
                } else if (record.type === 'quiz') {
                    return (
                        <>
                            <div>{record.title}</div>
                        </>
                    );
                } else if (record.type === 'test') {
                    return (
                        <>
                            <div>{record.title}</div>
                        </>
                    );
                } else {
                    return null;
                }
            }
        },
    ];

    return (
        <PreviewContainer>
            <DataTable columns={columns} dataSource={contents && [...contents].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))} loading={false} />
        </PreviewContainer>
    );
};

export default PreviewContent;
