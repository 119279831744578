import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Col, Row, Upload, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { StyledForm } from "./Student.styles";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  validationCharacters,
  validationCharactersAlphabets,
} from "../../constants";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { useCreateStudentMutation } from "../../common/services/student";

const { Option } = Select;
const { TextArea } = Input;

const CreateStudent = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [createStudent, { isLoading }] = useCreateStudentMutation();

  const user = useSelector((state) => state.userDetails);
  const data = useSelector((state) => state.getAllMaster);

  const [selectedMedium, setSelectedMedium] = useState(null);
  const [filteredStandards, setFilteredStandards] = useState([]);

  console.log(data, "data check for create student");

  useEffect(() => {
    if (user?.school) {
      form.setFieldsValue({
        school: user.school._id,
      });
    }
    if (user?.school && user?.school?.board.length > 0) {
      form.setFieldsValue({
        board: user.school.board[0]._id,
      });
    }
  }, [user, form]);

  useEffect(() => {
    if (selectedMedium) {
      const filtered = data?.standards?.filter(
        (standard) => standard.medium === selectedMedium
      );
      setFilteredStandards(filtered);
    } else {
      setFilteredStandards([]);
    }
  }, [selectedMedium, data]);

  const onFinish = async (values) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      password,
      city,
      board,
      medium,
      standard,
      address1,
      address2,
      country,
      state,
      school,
      profilePicture,
      schoolName = "WeLearn Digital School",
      confirmPassword,
    } = values;
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("confirmPassword", confirmPassword);
    formData.append("city", city);
    formData.append("board", board);
    formData.append("medium", medium);
    formData.append("standard", standard);
    formData.append("address1", address1);
    formData.append("address2", address2);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("school", school);
    formData.append("schoolName", schoolName);
    if (
      profilePicture &&
      profilePicture[0] &&
      profilePicture[0].originFileObj
    ) {
      formData.append("profilePicture", profilePicture[0].originFileObj);
    }

    try {
      const response = await createStudent(formData);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      navigate(`/student`);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const validatePhone = (rule, value, callback) => {
    const phoneRegex = /^\d{10}$/;
    if (!value || !value.match(phoneRegex)) {
      callback("Please enter a valid phone number");
    } else {
      callback();
    }
  };

  return (
    <>
      {/* <Button size="small" onClick={() => navigate("/student")}> */}
      <ArrowLeftOutlined
        className="cursor-pointer"
        onClick={() => navigate("/student")}
      />
      {/* </Button> */}
      <h2>Create Student</h2>

      <Spin spinning={isLoading} fullscreen />
      <StyledForm form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="firstName"
              label="First Name"
              required
              rules={[
                { required: false, message: "Please enter your first name" },
                {
                  validator: (rule, value, callback) =>
                    validationCharacters(
                      rule,
                      value,
                      callback,
                      "Please enter a valid first name"
                    ),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="lastName"
              label="Last Name"
              required
              rules={[
                { required: false, message: "Please enter your last name" },
                {
                  validator: (rule, value, callback) =>
                    validationCharacters(
                      rule,
                      value,
                      callback,
                      "Please enter a valid last name"
                    ),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="phone"
              label="Phone"
              required
              rules={[
                { required: false, message: "Please enter your phone number" },
                { validator: validatePhone },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="board"
              label="Board"
              rules={[{ required: true, message: "Please select your board" }]}
            >
              {user?.school && (
                <Select placeholder="Select a board" disabled>
                  {user?.school?.board.map((item) => (
                    <Option key={item?._id} value={item?._id}>
                      {item?.boardName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="school"
              label="School"
              rules={[{ required: true, message: "Please select your school" }]}
            >
              {user?.school && (
                <Select placeholder="Select a School" disabled>
                  <Option value={user.school._id} key={user.school._id}>
                    {user.school.schoolName}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="medium"
              label="Medium"
              rules={[{ required: true, message: "Please select your medium" }]}
            >
              <Select
                placeholder="Select a Medium"
                onChange={(value) => setSelectedMedium(value)}
              >
                {data?.mediums?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.mediumName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="standard"
              label="Standard"
              rules={[
                { required: true, message: "Please select your standard" },
              ]}
            >
              <Select placeholder="Select a Standard">
                {filteredStandards?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.standardName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="address1"
              label="Address 1"
              required
              rules={[
                { required: false, message: "Please enter your address" },
                {
                  validator: (rule, value, callback) =>
                    validationCharactersAlphabets(
                      rule,
                      value,
                      callback,
                      "Please enter your address 1 with at least 2 characters"
                    ),
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="address2"
              label="Address 2"
              required
              rules={[
                { required: false, message: "Please enter your address" },
                {
                  validator: (rule, value, callback) =>
                    validationCharactersAlphabets(
                      rule,
                      value,
                      callback,
                      "Please enter your address 2 with at least 2 characters"
                    ),
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="country"
              label="Country"
              required
              rules={[
                { required: false, message: "Please enter your country" },
                { validator: validationCharacters },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="state"
              label="State"
              required
              rules={[
                { required: false, message: "Please enter your state" },
                { validator: validationCharacters },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="city"
              label="City"
              required
              rules={[
                { required: false, message: "Please enter your city" },
                { validator: validationCharacters },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item
              name="schoolName"
              label="School Name"
              required
              rules={[
                {
                  required: false,
                  message: "Please enter your school name",
                },
                { validator: validationCharacters },
              ]}
            >
              <Input />
            </Form.Item>
          </Col> */}
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: "Please enter a password" }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Col span={6}>
          <Form.Item
            name="profilePicture"
            label="Profile Picture"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              accept="image/*"
              name="logo"
              listType="picture"
              beforeUpload={() => false}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
              <Button
                onClick={() => navigate("/student")}
                style={{ marginLeft: 8 }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledForm>
    </>
  );
};

export default CreateStudent;
