import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

const PermissionChecker = ({ resource, action, children }) => {
    const permissions = useSelector((state) => state.permissionsList);

    const hasPermission = () => {
        return permissions && permissions[resource] && permissions[resource][action];
    };

    return hasPermission() ? children : null;
};

PermissionChecker.propTypes = {
    resource: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default PermissionChecker;
