import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Col, Row, Select, Button } from "antd";
import { useUpdateStandardMutation, useGetAllStandardMutation } from "../../common/services/standard";
import { StyledForm } from "./Standard.styles";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { validationCharactersNumber } from "../../constants";

const EditStandard = () => {
    const { Option } = Select;
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const user = useSelector((state) => state.userDetails)
    const data = useSelector((state) => state.getAllMaster)

    const [updateStandardMute] = useUpdateStandardMutation();
    const [getStandardMutation, { data: standardData }] = useGetAllStandardMutation();

    useEffect(() => {
        const standardById = {
            id: id,
        };
        getStandardMutation(standardById)
    }, [id])

    useEffect(() => {
        if (standardData && standardData.data) {
            const { standardName, description, school, medium } = standardData.data[0];

            form.setFieldsValue({
                standardName,
                description,
                school: school ? school._id : undefined,
                medium: medium ? medium._id : undefined,
            });
        }
    }, [standardData, form]);

    const onFinish = async (values) => {
        const { standardName, school, description, medium } = values;

        const formData = {
            id,
            standardName,
            school,
            description,
            medium
        };

        try {
            const response = await updateStandardMute(formData);
            if (response.data) {
                toast.success(response.data.message)
            }
            if (response.error) {
                toast.error(response.error.data.message)
            }
            navigate(`/standard`);
        } catch (error) {
            console.error("Update error:", error);
        }
    };

    return (
        <>
            <h2>Edit Standard</h2>
            <StyledForm form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="standardName"
                            label="Standard Name"
                            required
                            rules={[
                                {
                                    required: false,
                                    message: "Please enter your standard name",
                                },
                                { validator: validationCharactersNumber },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="description"
                            label="Description"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="school"
                            label="School"
                            rules={[{ required: true, message: "Please select your school" }]}
                        >
                            {user?.school && (
                                <Select placeholder="Select a School" disabled>
                                    <Option value={user.school._id} key={user.school._id}>
                                        {user.school.schoolName}
                                    </Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="medium"
                            label="Medium"
                            rules={[{ required: true, message: "Please select your medium" }]}
                        >
                            <Select placeholder="Select a Medium">
                                {data?.mediums?.map((item) => (
                                    <Option value={item._id} key={item._id}>{item.mediumName}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                            <Button onClick={() => navigate("/standard")} style={{ marginLeft: 8 }}>
                                Cancel
                            </Button>
                            <Button onClick={() => navigate("/standard")} style={{ marginLeft: 8 }}>
                                Back
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </StyledForm>
        </>
    );
};

export default EditStandard;
