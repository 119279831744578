import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { useDeleteMediumMutation, useGetAllMediumMutation } from "../../common/services/medium";
import { PageHeader } from "./Medium.styles";
import { useSelector } from "react-redux";
import { Button, Input } from "antd";
import UserModal from "../../components/Modal";
import { toast } from "react-toastify";
import PermissionChecker from "../../components/PermissionChecker";

const Medium = () => {
  const navigate = useNavigate();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tempDeleteData, setTempDeleteData] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const [getAllMedium, { data, isLoading }] = useGetAllMediumMutation();
  const [deleteMedium] = useDeleteMediumMutation();

  const user = useSelector((state) => state.userDetails)

  useEffect(() => {
    if (user.school) {
      handleCallGetAPI()
    }
  }, [user]);

  const handleCallGetAPI = () => {
    const currentSchoolId = {
      schoolId: user.school._id,
    };
    getAllMedium(currentSchoolId);
  }

  const columns = [
    {
      title: "Medium Name",
      dataIndex: "mediumName",
      key: "mediumName",
    },
    {
      title: "Medium ID",
      dataIndex: "mediumId",
      key: "mediumId",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "School",
      dataIndex: "school",
      key: "school",
      render: (school) => <>{school?.schoolName}</>,
    },
  ];

  const handleCreateMediumClick = () => {
    navigate(`/medium/create`);
  };

  const handleEdit = (medium) => {
    navigate(`/medium/edit/${medium._id}`);
  };

  const handleDelete = (user) => {
    setDeleteModalVisible(true);
    setTempDeleteData(user);
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    setDeleteModalVisible(false);
    const mediumId = {
      id: tempDeleteData._id,
    };

    try {
      const response = await deleteMedium(mediumId);
      if (response.data) {
        toast.success(response.data.message)
      }
      if (response.error) {
        toast.error(response.error.data.message)
      }
      handleCallGetAPI()
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const actions = [
    {
      key: "edit",
      icon: <EditFilled />,
      onClick: handleEdit,
      label: "Edit",
      style: { backgroundColor: "#7c18ff" },
      permission: { resource: "medium", action: "update" }
    },
    {
      key: "delete",
      icon: <DeleteFilled />,
      onClick: handleDelete,
      label: "Delete",
      style: { backgroundColor: "#ff1818" },
      permission: { resource: "medium", action: "delete" }
    },
  ];

  const filteredMediums = data?.mediums.filter(
    (medium) =>
      medium.mediumName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      medium.mediumId.toLowerCase().includes(searchQuery.toLowerCase()) ||
      medium.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      medium.school.schoolName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <h2 style={{ paddingLeft: "5px" }}>Medium List</h2>
      <PageHeader>
        <Input
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ maxWidth: "200px" }}
        />
        <PermissionChecker resource="medium" action="create">
          <Button onClick={handleCreateMediumClick}>
            Create Medium
          </Button>
        </PermissionChecker>
      </PageHeader>
      <DataTable
        dataSource={filteredMediums && [...filteredMediums].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))}
        columns={columns}
        actions={actions}
        loading={isLoading}
      />
      <UserModal
        visible={deleteModalVisible}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={"Delete Medium"}
        description={"Are you sure you want to delete this medium?"}
      />
    </>
  );
};

export default Medium;
