import { Button, Table } from "antd";
import styled from "styled-components";

export const StyledTable = styled(Table)`
  width: 100%;

  .ant-table {
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    overflow: auto;

    .ant-table-thead > tr > th {
      background-color: #f2f2f2;
      border: 1px solid #e8e8e8;
    }

    .ant-table-tbody > tr > td {
      border: 1px solid #e8e8e8;
    }
  }
`;
export const ActionButton = styled(Button)`
  color: #fff;
  margin: 0 5px;
  font-weight: 600;
`;

export const ActionContainer = styled.span`
  display: flex;

  :hover {
    color: #fff !important;
    font-weight: 600;
  }
`;
