import { Layout, Menu } from "antd";
import SubMenu from "antd/es/menu/SubMenu";
import styled from "styled-components";

const { Header, Sider, Content } = Layout;

export const LayoutContainer = styled(Layout)`
  min-height: 100vh;
`;

export const Sidebar = styled(Sider)`
  background: #fff !important;
`;

export const LogoWrapper = styled.div`
  display: flex;
  padding-left: 24px;
  margin: 17px 0;
`;

export const AppLogo = styled.img`
  height: 30px;
  padding-right: 10px;
`;

export const CompanyName = styled.span`
  font-size: 20px;
  color: #000;
  font-weight: 600;
`;

export const TopHeader = styled(Header)`
  background: #fff;
  padding: 0 20px;
  display: flex;
  align-content: center;
  justify-content: space-between;
`;

export const SidebarMenu = styled(Menu)`
  font-weight: 600;
  color: #4d4444;

  // & > .ant-menu-item-selected {
  //   color: #fff;
  //   background-color: #5842be;
  // }
`;

export const SidebarSubMenu = styled(SubMenu)`
  font-weight: 600;
  color: #4d4444;

  // & > .ant-menu-item-selected {
  //   color: #fff;
  //   background-color: #5842be !important;
  // }
`;

export const MenuItem = styled(Menu.Item)``;

export const MainContent = styled(Content)`
  overflow: auto;
  height: 0;
  margin: 16px;
  scroll-behavior: smooth;
`;
