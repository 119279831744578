import { createSlice } from "@reduxjs/toolkit";

const bannerSlice = createSlice({
    name: "bannerData",
    initialState: [],
    reducers: {
        bannerData: (state, action) => {
            return action.payload
        },
    },
});

export const { bannerData } = bannerSlice.actions;
export default bannerSlice.reducer;
