import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const quizApi = createApi({
  reducerPath: "quizApi",
  baseQuery: fetchBaseQuery({
    // baseUrl: `http://localhost:8002/`,
    baseUrl: `${process.env.REACT_APP_BASE_URL}/v1/contentservice`,
    validateStatus: (response) => {
      if (response.status === 401) {
        window.location.href = "/login";
        return false;
      } else if (response.status >= 200 && response.status < 300) {
        return true;
      } else {
        throw new Error(`HTTP error ${response.status}`);
      }
    },
  }),
  endpoints: (builder) => ({
    getAllQuiz: builder.mutation({
      query: (body) => ({
        url: `api/quiz/getQuizzesAdminPanel`,
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    createQuiz: builder.mutation({
      query: (body) => ({
        url: "api/quiz/createQuiz",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    updateQuiz: builder.mutation({
      query: (body) => ({
        url: "api/quiz/updateQuiz",
        method: "PUT",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    deleteQuiz: builder.mutation({
      query: (body) => ({
        url: "api/quiz/deleteQuiz",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllQuizMutation,
  useCreateQuizMutation,
  useUpdateQuizMutation,
  useDeleteQuizMutation,
} = quizApi;
