import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const chapterApi = createApi({
  reducerPath: "chapterApi",
  baseQuery: fetchBaseQuery({
    // baseUrl: `http://localhost:8001/`,
    baseUrl: `${process.env.REACT_APP_BASE_URL}/v1/userservice`,
    validateStatus: (response) => {
      if (response.status === 401) {
        window.location.href = "/login";
        return false;
      } else if (response.status >= 200 && response.status < 300) {
        return true;
      } else {
        throw new Error(`HTTP error ${response.status}`);
      }
    },
  }),
  endpoints: (builder) => ({
    getAllChapter: builder.mutation({
      query: (body) => ({
        url: "api/user/getActiveChapters",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    createChapter: builder.mutation({
      query: (body) => ({
        url: "api/user/createChapter",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    updateChapter: builder.mutation({
      query: (body) => ({
        url: "api/user/updateChapter",
        method: "PUT",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    deleteChapter: builder.mutation({
      query: (body) => ({
        url: "api/user/deactivateChapter",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllChapterMutation,
  useCreateChapterMutation,
  useUpdateChapterMutation,
  useDeleteChapterMutation,
} = chapterApi;
