import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, Col, Row, Select, Button } from "antd";
import { useGetAllMediumMutation, useUpdateMediumMutation } from "../../common/services/medium";
import { StyledForm } from "./Medium.styles";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {  validationCharactersNumber } from "../../constants";

const EditMedium = () => {
    const { id } = useParams();
    const { Option } = Select;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const user = useSelector((state) => state.userDetails);
    const [updateMediumMute] = useUpdateMediumMutation();
    const [getMediumMutation, { data: mediumData }] = useGetAllMediumMutation();

    useEffect(() => {
        const mediumById = {
            id: id,
        };
        getMediumMutation(mediumById)
    }, [id])

    useEffect(() => {
        if (mediumData && mediumData.mediums) {
            const { mediumName, description, school, board } = mediumData.mediums[0];

            form.setFieldsValue({
                mediumName,
                description,
                board: board ? board._id : undefined,
                school: school ? school._id : undefined,
            });
        }
    }, [mediumData, form]);

    const onFinish = async (values) => {
        const { mediumName, school, description, board } = values;

        const formData = {
            id,
            mediumName,
            description,
            school,
            board,
        };

        try {
            const response = await updateMediumMute(formData);
            if (response.data) {
                toast.success(response.data.message)
            }
            if (response.error) {
                toast.error(response.error.data.message)
            }
            navigate(`/medium`);
        } catch (error) {
            console.error("Update error:", error);
        }
    };

    return (
        <>
            <h2>Edit Medium</h2>
            <StyledForm form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="mediumName"
                            label="Medium Name"
                            required
                            rules={[
                                {
                                    required: false,
                                    message: "Please enter your medium name",
                                },
                                { validator: validationCharactersNumber },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="description"
                            label="Description"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="school"
                            label="School"
                            rules={[{ required: true, message: "Please select your school" }]}
                        >
                            {user?.school && (
                                <Select placeholder="Select a School" disabled>
                                    <Option value={user.school._id} key={user.school._id}>
                                        {user.school.schoolName}
                                    </Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="board"
                            label="Board"
                            rules={[{ required: true, message: "Please select your board" }]}
                        >
                            {user?.school && (
                                <Select placeholder="Select a board" disabled>
                                    {user?.school?.board.map((item) => (
                                        <Option key={item?._id} value={item?._id}>
                                            {item?.boardName}
                                        </Option>
                                    ))
                                    }
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                            <Button onClick={() => navigate("/medium")} style={{ marginLeft: 8 }}>
                                Cancel
                            </Button>
                            <Button onClick={() => navigate("/medium")} style={{ marginLeft: 8 }}>
                                Back
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </StyledForm>
        </>
    );
};

export default EditMedium;
