/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "mathlive";

import { useNavigate, useParams } from "react-router-dom";
// import { Editor } from "react-draft-wysiwyg";
import { CKEditor } from "ckeditor4-react";
import CKEDITOR from "ckeditor4-react";

// import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
// import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  Checkbox,
  Spin,
  Drawer,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { OptionWrapper, StyledForm } from "./Question.styles";
import { useDispatch, useSelector } from "react-redux";
import { masterContentSeletedValue } from "../../common/features/contentSlice";
import {
  useGetAllQuestionMutation,
  useUpdateQuestionMutation,
} from "../../common/services/question";
import { toast } from "react-toastify";
import axios from "axios";

const EditQuestion = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { Option } = Select;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isQuestionVisible, setIsQuestionVisible] = useState(false);
  // const [isFieldEmpty, setIsFieldEmpty] = useState(false);
  const [filteredData, setFilteredData] = useState();
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [getQuestion, { data: questionData }] = useGetAllQuestionMutation();
  const [updateQuestionMute, { isLoading }] = useUpdateQuestionMutation();
  const [contents, setContents] = useState([
    { order: "", title: "", description: "", type: "" },
  ]);
  const [editorData, setEditorData] = useState("");
  const [questionUrl, setQuestionUrl] = useState("");
  const [open, setOpen] = useState(false);

  const data = useSelector((state) => state.getAllMaster);
  const user = useSelector((state) => state.userDetails);

  useEffect(() => {
    const questionById = {
      id: id,
      schoolId: user?.school?._id,
    };
    getQuestion(questionById);
  }, [id]);

  useEffect(() => {
    if (questionData && questionData.questions) {
      const {
        medium,
        standard,
        school,
        board,
        chapter,
        section,
        subject,
        options,
        question,
        hint,
        explainwithAnswer,
      } = questionData.questions[0];

      // const contentBlock = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(question)));

      const contentBlock = question;
      // setEditorState(contentBlock);
      setEditorData(contentBlock);

      form.setFieldsValue({
        boardId: board ? board._id : undefined,
        schoolId: school ? school._id : undefined,
        mediumId: medium ? medium._id : undefined,
        standardId: standard ? standard.standardName : undefined,
        chapterId: chapter ? chapter.chapterName : undefined,
        sectionId: section ? section.sectionName : undefined,
        subjectId: subject ? subject.subjectName : undefined,
        options: options.map((option) => ({
          option: option.option,
          isCorrect: option.isCorrect,
        })),
        hint: hint,
        explainwithAnswer: explainwithAnswer,
      });
      setIsQuestionVisible(true);
      const option = options.map((option) => ({
        option: option.option,
        isCorrect: option.isCorrect,
      }));
      setContents(option);
    }
  }, [questionData, form]);

  const onEditorStateChange = (event) => {
    const editor = event.editor;
    const data = editor.getData();
    setEditorData(data);
    // setEditorState(editorState);
    // const newContent = editorState.getCurrentContent().getPlainText().trim();
    //     if (newContent === '') {
    //         setIsFieldEmpty(newContent === '');
    //     } else {
    //         setIsFieldEmpty(false)
    //     }
  };

  const handleSelectionChange = (type, value) => {
    const newSelection = { type, value };
    dispatch(masterContentSeletedValue(newSelection));

    if (type === "mediumId") {
      const standard = data?.standards?.filter(
        (standard) => standard.medium === value
      );
      setFilteredData((prevData) => ({ ...prevData, standard }));
    }
    if (type === "standardId") {
      const subject = data?.subjects?.filter(
        (subject) => subject.standard === value
      );
      setFilteredData((prevData) => ({ ...prevData, subject }));
    }
    if (type === "subjectId") {
      const chapter = data?.chapters?.filter(
        (chapter) => chapter.subject === value
      );
      setFilteredData((prevData) => ({ ...prevData, chapter }));
    }
    if (type === "chapterId") {
      const section = data?.sections?.filter(
        (section) => section.chapter === value
      );
      setFilteredData((prevData) => ({ ...prevData, section }));
    }
  };

  // Function to handle file selection
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log(file, "file check edit question");
    if (!file) {
      toast.error("Please select a file to upload");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/userservice/api/user/uploadQuestionImage`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      setQuestionUrl(response?.data?.data);
      showDrawer();
    } catch (error) {
      toast.error("Error uploading file");
    }
  };

  const handleClickApply = (value) => {
    setIsQuestionVisible(true);
  };

  const onFinish = async (values) => {
    // const newContent = editorState.getCurrentContent().getPlainText().trim();

    const { hint, explainwithAnswer } = values;
    const { medium, standard, subject, chapter, section } =
      questionData?.questions[0];

    const formData = new FormData();
    // const Question = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    const Question = editorData;
    formData.append("id", id);
    formData.append("mediumId", medium._id);
    formData.append("standardId", standard._id);
    formData.append("subjectId", subject._id);
    formData.append("chapterId", chapter._id);
    formData.append("sectionId", section._id);
    formData.append("question", Question);
    formData.append("questionType", "text");
    formData.append("hint", hint);
    formData.append("explainwithAnswer", explainwithAnswer);
    values.options.forEach((data, index) => {
      formData.append(`options[${index}][option]`, data.option);
      formData.append(`options[${index}][isCorrect]`, data.isCorrect);
    });

    try {
      const response = await updateQuestionMute(formData);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      navigate(`/question`);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const triggerFileInputClick = () => {
    document.getElementById("hiddenFileInput").click();
  };

  const handleAddOption = () => {
    setContents([
      ...contents,
      { order: "", title: "", description: "", type: "" },
    ]);
  };

  const handleRemoveQuestion = (index) => {
    const newContents = [...contents];
    newContents.splice(index, 1);
    setContents(newContents);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(questionUrl)
      .then(() => {
        toast.success("URL Copied to Clipboard");
        // Optionally, you can display a message to the user indicating success.
      })
      .catch((err) => {
        toast.error("Failed to copy URL to Clipboard");
      });
  };

  return (
    <>
      <h2>Update Question</h2>
      <StyledForm form={form} onFinish={handleClickApply} layout="vertical">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="boardId"
              label="Board"
              rules={[{ required: true, message: "Please select your board" }]}
            >
              {user?.school && (
                <Select
                  placeholder="Select a board"
                  onChange={(value) => handleSelectionChange("boardId", value)}
                  disabled
                >
                  {user?.school?.board.map((item) => (
                    <Option key={item?._id} value={item?._id}>
                      {item?.boardName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="schoolId"
              label="School"
              rules={[{ required: true, message: "Please select your school" }]}
            >
              {user?.school && (
                <Select
                  placeholder="Select a School"
                  onChange={(value) => handleSelectionChange("schoolId", value)}
                  disabled
                >
                  <Option value={user.school._id} key={user.school._id}>
                    {user.school.schoolName}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="mediumId"
              label="Medium"
              rules={[{ required: true, message: "Please select your medium" }]}
            >
              <Select
                placeholder="Select a Medium"
                onChange={(value) => handleSelectionChange("mediumId", value)}
                disabled
              >
                {data?.mediums?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.mediumName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="standardId"
              label="Standard"
              rules={[
                { required: true, message: "Please select your standard" },
              ]}
            >
              <Select
                placeholder="Select a Standard"
                onChange={(value) => handleSelectionChange("standardId", value)}
                disabled
              >
                {filteredData?.standard?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.standardName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="subjectId"
              label="Subject"
              rules={[
                { required: true, message: "Please select your subject" },
              ]}
            >
              <Select
                placeholder="Select a Subject"
                onChange={(value) => handleSelectionChange("subjectId", value)}
                disabled
              >
                {filteredData?.subject?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.subjectName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="chapterId"
              label="Chapter"
              rules={[
                { required: true, message: "Please select your chapter" },
              ]}
            >
              <Select
                placeholder="Select a Chapter"
                onChange={(value) => handleSelectionChange("chapterId", value)}
                disabled
              >
                {filteredData?.chapter?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.chapterName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="sectionId"
              label="Section"
              rules={[
                { required: true, message: "Please select your section" },
              ]}
            >
              <Select
                placeholder="Select a Section"
                onChange={(value) => handleSelectionChange("sectionId", value)}
                disabled
              >
                {filteredData?.section?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.sectionName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            style={{ backgroundColor: "#1f292b", color: "#fff" }}
            type="primary"
            htmlType="submit"
            disabled
          >
            Apply
          </Button>
        </Form.Item>
      </StyledForm>
      {isQuestionVisible && (
        <>
          <Drawer title="Image URL" onClose={onClose} open={open}>
            <p>{questionUrl}</p>
            <Button type="primary" size="large" onClick={copyToClipboard}>
              Copy Image Url
            </Button>
          </Drawer>
          <Spin spinning={isLoading} fullscreen />
          <StyledForm form={form} onFinish={onFinish} layout="vertical">
            <div>
              <h2>Equation Box</h2>
              <math-field
                style={{
                  fontSize: "1.5rem",
                  width: "50rem",
                  border: "2px gray solid",
                }}
              ></math-field>
            </div>
            <Col>
              <div className="flexdiv">
                <Button type="primary" onClick={triggerFileInputClick}>
                  Manual Image Upload
                </Button>
                <input
                  type="file"
                  id="hiddenFileInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
              <Form.Item name="question" label="Question" required>
                <div
                  style={{
                    border: "1px solid #cbcbcb",
                    borderRadius: "3px",
                    marginBottom: "20px",
                  }}
                >
                  {/*   <Editor
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={onEditorStateChange}

                                    />   */}
                  <CKEditor
                    data={editorData}
                    onChange={onEditorStateChange}
                    config={{
                      extraPlugins: "mathjax", // Step 1: Ensure the MathJax plugin is included
                      mathJaxLib:
                        "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-AMS_HTML", // Step 2: Set the path to the MathJax library
                      toolbar: [
                        { name: "document", items: ["Source", "-", "Save"] },
                        {
                          name: "clipboard",
                          items: [
                            "Cut",
                            "Copy",
                            "Paste",
                            "PasteText",
                            "-",
                            "Undo",
                            "Redo",
                          ],
                        },
                        {
                          name: "editing",
                          items: ["Find", "Replace", "-", "SelectAll"],
                        },
                        {
                          name: "insert",
                          items: [
                            "Image",
                            "Mathjax",
                            "Table",
                            "HorizontalRule",
                            "SpecialChar",
                          ],
                        }, // Include "Image" in the insert group
                        "/",
                        {
                          name: "basicstyles",
                          items: [
                            "Bold",
                            "Italic",
                            "Strike",
                            "Subscript",
                            "Superscript",
                            "-",
                            "RemoveFormat",
                          ],
                        },
                        {
                          name: "paragraph",
                          items: [
                            "NumberedList",
                            "BulletedList",
                            "-",
                            "Outdent",
                            "Indent",
                            "-",
                            "Blockquote",
                          ],
                        },
                        { name: "styles", items: ["Styles", "Format"] },
                        { name: "links", items: ["Link", "Unlink"] },
                        { name: "tools", items: ["Maximize"] },
                      ],
                      height: 320,
                    }}
                    initData={editorData}
                  />
                </div>
                {/* {isFieldEmpty &&
                                <div style={{ color: 'red' }}>Please enter a valid question with at least 2 characters.</div>
                            } */}
              </Form.Item>
            </Col>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  name="hint"
                  label="Hint"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a hint",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="explainwithAnswer"
                  label="Explain with Answer"
                  rules={[
                    {
                      required: true,
                      message: "Please enter explain with answer",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <OptionWrapper>
              {contents.map((content, index) => (
                <div key={index}>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        name={["options", index, "option"]}
                        rules={[
                          {
                            required: false,
                            message: "Please enter your options name",
                          },
                          // {
                          //   validator: (rule, value, callback) =>
                          //     validationCharactersAlphabetsForOptions(
                          //       rule,
                          //       value,
                          //       callback,
                          //       "Please enter a valid options"
                          //     ),
                          // },
                        ]}
                      >
                        <Input placeholder="Enter a option" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={["options", index, "isCorrect"]}
                        valuePropName="checked"
                        initialValue={false}
                      >
                        <Checkbox>Is Correct?</Checkbox>
                      </Form.Item>
                    </Col>
                    {contents.length > 1 && (
                      <Button
                        type="dashed"
                        onClick={() => handleRemoveQuestion(index)}
                        icon={<MinusCircleOutlined />}
                      ></Button>
                    )}
                  </Row>
                  {index === contents.length - 1 && (
                    <Button
                      type="dashed"
                      style={{ marginBottom: "20px" }}
                      disabled={contents.length === 8}
                      onClick={handleAddOption}
                      icon={<PlusOutlined />}
                    >
                      Add Option
                    </Button>
                  )}
                </div>
              ))}
            </OptionWrapper>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={contents.length <= 1}
                  >
                    Update Question
                  </Button>
                  <Button
                    onClick={() => navigate("/question")}
                    style={{ marginLeft: 8 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => navigate("/question")}
                    style={{ marginLeft: 8 }}
                  >
                    Back
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </StyledForm>
        </>
      )}
    </>
  );
};

export default EditQuestion;
