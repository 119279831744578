import React, { useEffect, useState } from "react";
import { Form, Input, Col, Row, Upload, Button, Spin } from "antd";
import { SubjectLogo } from "./Subject.styles";
import { useDispatch, useSelector } from "react-redux";
import { useCreateSubjectMutation, useDeleteSubjectMutation, useGetAllSubjectMutation, useUpdateSubjectMutation } from "../../common/services/subject";
import { DeleteFilled, EditFilled, UploadOutlined } from "@ant-design/icons";
import { toast } from 'react-toastify';
import DataTable from "../../components/DataTable";
import UserModal from "../../components/Modal";
import { filteredSubject } from "../../common/features/subjectSlice";
import PermissionChecker from "../../components/PermissionChecker";
import {  validationCharactersNumber } from "../../constants";

const Subject = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [filteredValue, setFilteredValue] = useState()
    const [editSubject, setEditSubject] = useState(null);
    const [updateTempSubject, setUpdateTempSubject] = useState()
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [tempDeleteData, setTempDeleteData] = useState();

    const masterSeletedValue = useSelector((state) => state.masterSeletedValue)
    const user = useSelector((state) => state.userDetails)

    const [createSubjectMute, { isLoading: createLoader }] = useCreateSubjectMutation();
    const [updateSubjectMute, { isLoading: updateLoader }] = useUpdateSubjectMutation();
    const [deleteSubjectMute] = useDeleteSubjectMutation();
    const [getAllSubject, { data, isLoading }] = useGetAllSubjectMutation();


    useEffect(() => {
        handleGetSubjetList()
    }, [user]);

    const handleGetSubjetList = () => {
        if (user.school) {
            const currentSchoolId = {
                schoolId: user.school._id,
            };
            getAllSubject(currentSchoolId);
        }
    }

    useEffect(() => {
        if (data?.subjects) {
            const filteredSubjects = data.subjects.filter(subject => {
                return masterSeletedValue.every(filter => {
                    return subject[filter.type]?._id === filter.value;
                });
            });
            setFilteredValue(filteredSubjects)
            dispatch(filteredSubject(filteredSubjects));
        }
    }, [data, masterSeletedValue])

    const handleEditSubject = (subject) => {
        setEditSubject(true)
        setUpdateTempSubject(subject)
        form.setFieldsValue({
            subjectName: subject.subjectName,
            abbreviation: subject.abbreviation,
            description: subject.description,
            subjectLogo: subject.subjectLogo
                ? [{
                    uid: '-1',
                    name: subject.subjectLogo.path,
                    status: 'done',
                    url: `https://ilearnbackend.s3.ap-south-1.amazonaws.com/${subject.subjectLogo.path}`
                }]
                : [],
        });
    }

    const handleDeleteSubject = (subject) => {
        setDeleteModalVisible(true);
        setTempDeleteData(subject);
    };

    const handleCancelDelete = () => {
        setDeleteModalVisible(false);
    };

    const handleConfirmDelete = async () => {
        setDeleteModalVisible(false);
        const formDataDelete = {
            "id": tempDeleteData._id
        }
        const response = await deleteSubjectMute(formDataDelete);
        if (response.data) {
            toast.success(response.data.message)
        }
        if (response.error) {
            toast.error(response.error.data.message)
        }
        handleGetSubjetList()
    };

    const handleCancelClick = () => {
        form.resetFields();
        setEditSubject(false)
    }

    const onFinish = async (values) => {
        const { subjectName, abbreviation, description, subjectLogo } = values;

        const formData = new FormData();
        masterSeletedValue.map((item) => {
            formData.append(item.type, item.value);
        })
        formData.append("subjectName", subjectName);
        formData.append("abbreviation", abbreviation);
        formData.append("description", description);
        if (subjectLogo && subjectLogo[0] && subjectLogo[0].originFileObj) {
            formData.append("subjectLogo", subjectLogo[0].originFileObj);
        }

        try {
            if (editSubject) {
                const formDataUpdate = new FormData();
                formDataUpdate.append("id", updateTempSubject._id);
                formDataUpdate.append("subjectName", subjectName);
                formDataUpdate.append("abbreviation", abbreviation);
                if (subjectLogo && subjectLogo[0] && subjectLogo[0].originFileObj) {
                    formDataUpdate.append("subjectLogo", subjectLogo[0].originFileObj);
                }

                const response = await updateSubjectMute(formDataUpdate);
                if (response.data) {
                    toast.success(response.data.message)
                }
                if (response.error) {
                    toast.error(response.error.data.message)
                }
                form.resetFields();
                setEditSubject(false)
                handleGetSubjetList()
            } else {
                const response = await createSubjectMute(formData);
                if (response.data) {
                    toast.success(response.data.message)
                }
                if (response.error) {
                    toast.error(response.error.data.message)
                }
                form.resetFields();
                handleGetSubjetList()
            }
        } catch (error) {
            toast.error(error);
        }
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const columns = [
        {
            title: "Subject Logo",
            dataIndex: "subjectLogo",
            key: "subjectLogo",
            render: (logo) => <>{logo ? <SubjectLogo src={`https://ilearnbackend.s3.ap-south-1.amazonaws.com/${logo?.path}`} alt="Subject Logo" /> : '-'}</>,
        },
        {
            title: "Subject Name",
            dataIndex: "subjectName",
            key: "subjectName",
        },
        {
            title: "Abbreviation",
            dataIndex: "abbreviation",
            key: "abbreviation",
        }
    ];

    const actions = [
        {
            key: "edit",
            icon: <EditFilled />,
            onClick: handleEditSubject,
            label: "",
            style: { backgroundColor: "#14828f" },
            permission: { resource: "master", action: "update" }
        },
        {
            key: "delete",
            icon: <DeleteFilled />,
            onClick: handleDeleteSubject,
            label: "",
            style: { backgroundColor: "#cb192f" },
            permission: { resource: "master", action: "delete" }
        },
    ];

    return (
        <>
            <h3>{editSubject ? "Update" : "Create"} Subject</h3>
            <Spin spinning={createLoader || updateLoader} fullscreen />
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name="subjectName"
                            label="Subject Name"
                            required
                            rules={[
                                {
                                    required: false,
                                    message: "Please enter your subject name",
                                },
                                { validator: validationCharactersNumber },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="abbreviation"
                            label="Abbreviation"
                            required
                            rules={[
                                {
                                    required: false,
                                    message: "Please enter your abbreviation name",
                                },
                                { validator: validationCharactersNumber },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name="description"
                            label="Description"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="subjectLogo"
                            label="Subject Logo"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload
                                accept="image/*"
                                name="logo"
                                listType="picture"
                                beforeUpload={() => false}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ display: "flex", alignItems: 'flex-end' }}>
                        <Form.Item>
                            <PermissionChecker resource="master" action="create">
                                <Button htmlType="submit">{editSubject ? "Update" : "Add Subject"}</Button>
                            </PermissionChecker>
                            {editSubject && <Button onClick={handleCancelClick} style={{ marginLeft: "10px" }} danger >Cancel</Button>}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <DataTable
                dataSource={filteredValue && [...filteredValue].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))}
                columns={columns}
                actions={actions}
                loading={isLoading}

            />
            <UserModal
                visible={deleteModalVisible}
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                title={"Delete Subject"}
                description={"Are you sure you want to delete this subject?"}
            />
        </>
    );
};

export default Subject;
