import React from "react";

import { Row, Col, Typography } from "antd";
import { eChart } from "../config";
import { EChartWrapper } from "./EChart.styles";

const EChart = () => {
  const { Title, Paragraph } = Typography;

  const items = [
    {
      Title: "53",
      user: "Users",
    },
    {
      Title: "5",
      user: "Active",
    },
    {
      Title: "10",
      user: "Test completed",
    },
    {
      Title: "82",
      user: "Items",
    },
  ];

  return (
    <>
      <div id="chart">
        <EChartWrapper
          options={eChart.options}
          series={eChart.series}
          type="bar"
          height={220}
          width={"100%"}
        />
      </div>
      <div>
        <Title level={5}>Active Users</Title>
        <Paragraph>
          than last week <span>+30%</span>
        </Paragraph>
        {/* <Paragraph>
          Contrary to popular belief, Lorem Ipsum is not simply random text. It
          has roots in a piece of classical Latin literature
        </Paragraph> */}
        <Row gutter>
          {items.map((v, index) => (
            <Col xs={6} xl={6} sm={6} md={6} key={index}>
              <div>
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default EChart;
