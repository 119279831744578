import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Col, Row, Upload, Button } from "antd";
import { useCreateBoardMutation } from "../../common/services/board";
import { StyledForm } from "./Board.styles";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import {  validationCharactersNumber } from "../../constants";

const CreateBoard = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [createBoardMute] = useCreateBoardMutation();

  const onFinish = async (values) => {
    const { boardName, description, boardLogo } = values;
    const formData = new FormData();
    formData.append("boardName", boardName);
    formData.append("description", description);
    if (boardLogo && boardLogo[0] && boardLogo[0].originFileObj) {
      formData.append("boardLogo", boardLogo[0].originFileObj);
    }

    try {
      const response = await createBoardMute(formData);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      navigate(`/board`);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      {/* <Button size="small" onClick={() => navigate("/board")}> */}
      <ArrowLeftOutlined
        className="cursor-pointer"
        onClick={() => navigate("/board")}
      />
      {/* </Button> */}
      <h2>Create Board</h2>
      <StyledForm form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="boardName"
              label="Board Name"
              required
              rules={[
                {
                  required: false,
                  message: "Please enter your board name",
                },
                { validator: validationCharactersNumber },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="description" label="Description">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="boardLogo"
              label="Board Logo"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                name="logo"
                listType="picture"
                beforeUpload={() => false}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
              <Button
                onClick={() => navigate("/board")}
                style={{ marginLeft: 8 }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledForm>
    </>
  );
};

export default CreateBoard;
