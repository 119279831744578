import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import { DeleteFilled, EditFilled, EyeOutlined } from "@ant-design/icons";
import { PageHeader, PermissionName, PermissionWrapper } from "./Roles.styles";
import { useNavigate } from "react-router-dom";
import {
  useDeleteRoleMutation,
  useGetAllRolesMutation,
} from "../../common/services/roles";
import { Button, Drawer, Input, Tag } from "antd";
import UserModal from "../../components/Modal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { intialRoleList } from "../../common/features/roleSlice";
import PermissionChecker from "../../components/PermissionChecker";

const Roles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getAllActiveRoles, { data, isLoading, error }] =
    useGetAllRolesMutation();
  const [deleteRole] = useDeleteRoleMutation();

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tempDeleteUserData, setTempDeleteUserData] = useState();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [previewPermission, setPreviewPermission] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getAllActiveRoles();
  }, [getAllActiveRoles]);

  useEffect(() => {
    dispatch(intialRoleList(data?.roles[0]));
  }, [data]);

  if (error) {
    toast.error(error.message);
  }

  const columns = [
    {
      title: "Role Name",
      dataIndex: "name",
      key: "name",
    },
  ];

  const drawerColumns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Permissions",
      key: "permissions",
      render: (text, record) => (
        <div>
          {Object.keys(record.permissions).map((permissionType) => (
            <PermissionWrapper key={permissionType}>
              <PermissionName>{permissionType} : </PermissionName>
              {Object.keys(record.permissions[permissionType]).map((action) => (
                <>
                  <span key={action}>
                    {record.permissions[permissionType][action] ? (
                      <Tag color={"green"}>{action}</Tag>
                    ) : (
                      <Tag color={"red"}>{action}</Tag>
                    )}
                  </span>
                </>
              ))}
            </PermissionWrapper>
          ))}
        </div>
      ),
    },
  ];

  const handleClickPreview = (data) => {
    setPreviewPermission([data]);
    setDrawerVisible(true);
  };

  const handleCreateRoleClick = () => {
    navigate(`/role/create`);
  };

  const handleEditRoleClick = (role) => {
    navigate(`/role/edit/${role._id}`);
  };

  const handleDeleteRoleClick = (role) => {
    setDeleteModalVisible(true);
    setTempDeleteUserData(role);
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    setDeleteModalVisible(false);

    const roleData = {
      id: tempDeleteUserData._id,
    };

    try {
      const response = await deleteRole(roleData);
      toast.success(response.data.message);
      if (response.error) {
        toast.error(response.error.data.message);
      }
      getAllActiveRoles();
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const actions = [
    {
      key: "preview",
      icon: <EyeOutlined />,
      onClick: handleClickPreview,
      label: "Preview",
      style: { backgroundColor: "#313131" },
      permission: { resource: "role", action: "read" },
    },
    {
      key: "edit",
      icon: <EditFilled />,
      onClick: handleEditRoleClick,
      label: "Edit",
      style: { backgroundColor: "#7c18ff" },
      permission: { resource: "role", action: "update" },
    },
    {
      key: "delete",
      icon: <DeleteFilled />,
      onClick: handleDeleteRoleClick,
      label: "Delete",
      style: { backgroundColor: "#ff1818" },
      permission: { resource: "role", action: "delete" },
    },
  ];

  const filteredRoles = data?.roles.filter((role) =>
    role.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <h2 style={{ paddingLeft: "5px" }}>Role List</h2>
      <PageHeader>
        <Input
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ maxWidth: "200px" }}
        />
        <PermissionChecker resource="role" action="create">
          <Button onClick={handleCreateRoleClick}>Create Role</Button>
        </PermissionChecker>
      </PageHeader>
      <DataTable
        dataSource={
          filteredRoles &&
          [...filteredRoles].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        }
        columns={columns}
        actions={actions}
        loading={isLoading}
      />
      <Drawer
        title="Permission Preview"
        placement="right"
        closable={true}
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width={800}
      >
        <DataTable
          dataSource={previewPermission}
          columns={drawerColumns}
          loading={false}
        />
      </Drawer>
      <UserModal
        visible={deleteModalVisible}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={"Delete Role"}
        description={"Are you sure you want to delete this role?"}
      />
    </>
  );
};

export default Roles;
