import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Col, Row, Select, Button } from "antd";
import { useCreateMediumMutation } from "../../common/services/medium";
import { StyledForm } from "./Medium.styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {  validationCharactersNumber } from "../../constants";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useGetAllMasterMutation } from "../../common/services/schools";
import { getAllMaster } from "../../common/features/schoolSlice";

const CreateMedium = () => {
  const { Option } = Select;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const user = useSelector((state) => state.userDetails);
  const [createMediumMute] = useCreateMediumMutation();
  const [getMasterDetails, { data: getMaster }] = useGetAllMasterMutation();

  useEffect(() => {
    if (user?.school) {
      form.setFieldsValue({
        school: user.school._id,
      });
    }
    if (user?.school && user?.school?.board.length > 0) {
      form.setFieldsValue({
        board: user.school.board[0]._id,
      });
    }
  }, [user, form]);

  const onFinish = async (values) => {
    const { mediumName, school, description, board } = values;

    const formData = {
      mediumName,
      description,
      school,
      board,
    };

    try {
      const response = await createMediumMute(formData);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      await getMasterDetails({ schoolId: user?.school?._id });
      await dispatch(getAllMaster(getMaster));

      navigate(`/medium`);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <>
      {/* <Button size="small" onClick={() => navigate("/medium")}> */}
      <ArrowLeftOutlined
        onClick={() => navigate("/medium")}
        className="cursor-pointer"
      />
      {/* </Button> */}
      <h2>Create Medium</h2>
      <StyledForm form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="mediumName"
              label="Medium Name"
              required
              rules={[
                {
                  required: false,
                  message: "Please enter your medium name",
                },
                { validator: validationCharactersNumber },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="description" label="Description">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="school"
              label="School"
              rules={[{ required: true, message: "Please select your school" }]}
            >
              {user?.school && (
                <Select placeholder="Select a School" disabled>
                  <Option value={user.school._id} key={user.school._id}>
                    {user.school.schoolName}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="board"
              label="Board"
              rules={[{ required: true, message: "Please select your board" }]}
            >
              {user?.school && (
                <Select placeholder="Select a board" disabled>
                  {user?.school?.board.map((item) => (
                    <Option key={item?._id} value={item?._id}>
                      {item?.boardName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
              <Button
                onClick={() => navigate("/medium")}
                style={{ marginLeft: 8 }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledForm>
    </>
  );
};

export default CreateMedium;
